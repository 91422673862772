import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router";
import { NavLink } from "react-router-dom";
import {
  faArrowLeftToLine,
  faArrowRightFromLine,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ClavaContext } from "../../../config/contexts";
import { translate, TranslatorKeys } from "../../../config/translator";
import { isContentManager } from "../../../config/utils";
import AdminAds from "./AdminAds";
import AdminAdministration from "./AdminAdministration";
import AdminUser from "./AdminUser";
import AdminLeague from "./AdminLeague";

const Adminpanel: React.FC = () => {
  const { user, l } = useContext(ClavaContext);
  const navigate = useNavigate();
  const { adminSite } = useParams();
  const admin = useMemo(() => isContentManager(user), [user]);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = useCallback(() => {
    setSidebarOpen((so) => !so);
  }, []);
  useEffect(() => {
    if (!admin) {
      setTimeout(() => {
        navigate("/profile");
      }, 2000);
    }
  }, [navigate, admin]);
  return (
    <div className="flex flex-row items-stretch justify-stretch">
      <div
        className={`flex flex-col items-stretch justify-start p-4 overflow-hidden transition-all ${
          sidebarOpen
            ? "max-w-xl min-w-72 border-r border-r-light-gray dark:border-r-light-gray-dark"
            : "max-w-0"
        }`}
      >
        <button
          type="button"
          className="flex flex-row items-center justify-between py-2"
          onClick={toggleSidebar}
        >
          {sidebarOpen && <span className="font-bold">Backoffice</span>}
          <FontAwesomeIcon
            icon={sidebarOpen ? faArrowLeftToLine : faArrowRightFromLine}
          />
        </button>
        <NavLink
          to="/backoffice/administration"
          className={`p-2 pl-6 rounded-xl ${
            adminSite === "administration" || typeof adminSite === "undefined"
              ? "bg-primary text-white"
              : ""
          }`}
        >
          {translate("administration", l)}
        </NavLink>
        <NavLink
          to="/backoffice/users"
          className={`p-2 pl-6 rounded-xl ${
            adminSite === "users" ? "bg-primary text-white" : ""
          }`}
        >
          {translate("users", l)}
        </NavLink>
        <NavLink
          to="/backoffice/matches"
          className={`p-2 pl-6 rounded-xl ${
            adminSite === "matches" ? "bg-primary text-white" : ""
          }`}
        >
          {translate("matches", l)}
        </NavLink>
        <NavLink
          to="/backoffice/ads"
          className={`p-2 pl-6 rounded-xl ${
            adminSite === "ads" ? "bg-primary text-white" : ""
          }`}
        >
          {translate("ads", l)}
        </NavLink>
        <NavLink
          to="/backoffice/news"
          className={`p-2 pl-6 rounded-xl ${
            adminSite === "news" ? "bg-primary text-white" : ""
          }`}
        >
          {translate("news", l)}
        </NavLink>
        <NavLink
          to="/backoffice/leagues"
          className={`p-2 pl-6 rounded-xl ${
            adminSite === "leagues" ? "bg-primary text-white" : ""
          }`}
        >
          {translate("leagues", l)}
        </NavLink>
      </div>
      <div className="flex-1 flex flex-col items-stretch justify-start">
        <div className="flex flex-row items-center justify-between p-4 border-b border-b-light-gray dark:border-b-light-gray-dark">
          {adminSite ? (
            <h5 className="font-bold text-lg">
              {translate(adminSite as TranslatorKeys, l)}
            </h5>
          ) : (
            <h5 className="font-bold text-lg">
              {translate("administration", l)}
            </h5>
          )}
        </div>
        {admin ? (
          <div className="bg-bg dark:bg-bg-dark p-2">
            {adminSite === "users" ? (
              <AdminUser />
            ) : /* adminSite === 'videos' ? (
                <AdminVideo />
              ) :   adminSite === "matches" ? (
              <AdminMatch />
            ) :*/ adminSite === "ads" ? (
              <AdminAds />
            ) : adminSite === "leagues" ? (
              <AdminLeague />
            ) : (
              /* adminSite === "administration" ? (
              <AdminAdministration />
            ) : adminSite === "leagues" ? (
              <AdminLeague />
            ) : adminSite === "news" ? (
              <AdminNews />
            ) :*/ <AdminAdministration />
            )}
          </div>
        ) : (
          <div className="adminpanel-content">
            <span className="text-danger">{translate("yourNotAdmin", l)}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Adminpanel;
// reload
