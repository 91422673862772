import { ConnectedProps } from "react-redux";
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { Ad, AdPositionEnum } from "../../../client/api";
import { ClavaContext } from "../../../config/contexts";
import ClavaImage from "../ClavaImage";
import { connector } from "./redux";
import { isPremium } from "../../../config/utils";
import { fb } from "../../../config/firebase";

const MIN_PRIO = 11;
function getAdByPrio(ads: Ad[], onlyPrio1 = false): Ad {
  if (onlyPrio1) {
    const filtered = ads.filter((a) => a.priority === 1);
    if (filtered.length)
      return filtered[Math.floor(Math.random() * filtered.length)];
  }
  const selector = ads.reduce<number[]>(
    (prev, current, index) =>
      prev.concat(Array(MIN_PRIO - current.priority).fill(index)),
    []
  );
  return ads[selector[Math.floor(Math.random() * selector.length)]];
}

const ClavaAd: React.FC<ConnectedProps<typeof connector>> = ({
  priority,
  getAds,
  type,
  rounded,
  ads,
}) => {
  const { user } = useContext(ClavaContext);
  const premium = useMemo(() => isPremium(user), [user]);
  const arePresent = useMemo(() => ads.length !== 0, [ads]);
  useEffect(() => {
    if (!arePresent) getAds(type);
  }, [arePresent, type, premium, getAds]);

  const selected = useMemo(() => {
    if (ads.length === 0) return undefined;
    return getAdByPrio(ads, !!priority);
  }, [ads, priority]);
  const onPress = useCallback(() => {
    if (selected) {
      fb().logEvent("ad_clicked_web", { name: selected.name });
      window.open(selected.url, "_blank");
    } else window.open("mailto://ad@clava-sports.com");
  }, [selected]);

  if (isPremium(user)) return null;
  if (selected) {
    fb().logEvent("ad_view_web", { name: selected.name });
    if (type === ("WEB_BACKGROUND" as AdPositionEnum)) {
      return (
        <button
          type="button"
          className="absolute top-0 left-0 w-[100vw]"
          onClick={onPress}
        >
          <ClavaImage image={selected.imageDesktop} width="100%" />
        </button>
      );
    }
    return (
      <button
        type="button"
        className={`max-w-fit max-h-fit border-0 m-0 outline-0 p-0 ${
          rounded ? "rounded-2xl overflow-hidden" : ""
        }`}
        onClick={onPress}
      >
        <ClavaImage image={selected.imageMobile} width="100%" />
      </button>
    );
  }
  return null;
};

export default connector(ClavaAd);
