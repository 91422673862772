import React, {
  CSSProperties,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { ClavaContext, DropDownContext } from "../../../config/contexts";
import { translate } from "../../../config/translator";
import { getElementLayout } from "../../../config/utils";

export declare type DropdownItem<T extends string | number> = {
  key: T;
  label: string;
};

type ClavaDropdownProps<T extends string | number> = {
  onChange: (val: T | undefined) => void;
  value?: T;
  placeholder?: string;
  disabled?: boolean;
  withoutChoose?: boolean;
  label?: string;
  iconL?: FontAwesomeIconProps["icon"];
  items: DropdownItem<T>[];
  transparent?: boolean;
  primary?: boolean;
};

function ClavaDropdown<T extends string | number>({
  onChange,
  value,
  placeholder,
  disabled,
  label,
  withoutChoose,
  primary,
  iconL,
  transparent,
  items,
}: ClavaDropdownProps<T>) {
  const { l } = useContext(ClavaContext);
  const { setOpen, setClose } = useContext(DropDownContext);
  const [rect, setRect] = useState<DOMRect | false>(false);
  const realPlaceholder = useMemo(() => {
    if (!placeholder) return translate("select", l);
    return placeholder;
  }, [l, placeholder]);

  const inputClass = useMemo(() => {
    let style = "w-full outline-0 rounded-xl";
    if (!transparent) {
      style +=
        " active:bg-input-bg-active active:dark:bg-input-bg-active-dark focus:bg-input-bg-active focus:dark:bg-input-bg-active-dark border-[0.5px] active:dark:border-input-border-active-dark focus:dark:border-input-border-active-dark py-2";
      if (disabled) {
        style += " border-input-border opacity-60";
      } else {
        style +=
          " bg-input-bg dark:bg-input-bg-dark border-input-border dark:border-input-border-dark active:border-input-border-active focus:border-input-border-active";
      }
    }
    if (value) {
      if (primary) {
        style += " !text-primary";
      } else {
        style += " text-font dark:text-font-dark";
      }
    } else {
      style += " text-placeholder dark:text-placeholder-dark";
    }
    if (iconL) {
      style += " px-8";
    } else {
      style += " pl-2 pr-8";
    }

    return style;
  }, [disabled, iconL, primary, transparent, value]);
  const onClose = useCallback(() => {
    setRect(false);
  }, []);
  const realValue = useMemo(
    () => items.find((it) => it.key === value)?.label,
    [items, value]
  );
  const onOpen = useCallback(
    (e: React.MouseEvent) => {
      if (!disabled) {
        const clicked = e.target as HTMLElement;
        setRect(getElementLayout(clicked, "clava-dropdown"));
      }
    },
    [disabled]
  );
  const openStyle = useMemo<CSSProperties>(() => {
    if (!rect) {
      return {
        top: 0,
        left: 0,
      };
    }
    return {
      top: rect.bottom + window.scrollY,
      maxWidth: rect.width,
      left: rect.left,
    };
  }, [rect]);
  useEffect(() => {
    if (rect) {
      setOpen(
        <div
          className="absolute w-full h-24 z-50 overflow-x-hidden overflow-y-scroll bg-bg-secondary dark:bg-bg-secondary-dark rounded-xl shadow-shadow dark:shadow-shadow-dark drop-shadow-lg"
          style={openStyle}
        >
          {!withoutChoose && (
            <button
              className={`px-4 min-w-full py-1 border-none flex flex-row items-center justify-start hover:bg-primary-20 text-sm text-font dark:text-font-dark ${
                value === undefined ? "!bg-primary !text-white" : ""
              }`}
              onClick={() => {
                onChange(undefined);
                onClose();
              }}
              type="button"
            >
              {translate("options", l)}
            </button>
          )}
          {items.map((item) => (
            <button
              className={`px-4 min-w-full py-1 border-none flex flex-row items-center justify-start hover:bg-primary-20 text-sm text-font dark:text-font-dark ${
                value === undefined ? "!bg-primary !text-white" : ""
              }`}
              type="button"
              onClick={() => {
                onChange(item.key);
                onClose();
              }}
              key={`item-${item.key.toString()}`}
            >
              {item.label}
            </button>
          ))}
        </div>
      );
    } else {
      setClose();
    }
  }, [
    setOpen,
    setClose,
    rect,
    openStyle,
    withoutChoose,
    value,
    l,
    items,
    onChange,
    onClose,
  ]);
  return (
    <div
      className="relative flex flex-col items-start justify-stretch w-full"
      id="clava-dropdown"
    >
      {!!label && <span className="font-semibold text-sm mb-1">{label}</span>}
      <button onClick={onOpen} className={inputClass} type="button">
        {realValue ?? realPlaceholder}
      </button>
      <FontAwesomeIcon
        onClick={onOpen}
        icon={faChevronDown}
        className={`w-4 h-4 right-3 absolute cursor-pointer ${
          primary ? "!text-primary" : ""
        } ${transparent ? "bottom-1.5" : "bottom-3.5"}`}
      />
      {!!iconL && (
        <FontAwesomeIcon
          icon={iconL}
          className="w-4 h-4 bottom-3.5 left-3 absolute"
        />
      )}
    </div>
  );
}

ClavaDropdown.defaultProps = {
  disabled: undefined,
  label: undefined,
  iconL: undefined,
  placeholder: undefined,
  withoutChoose: undefined,
  value: undefined,
  primary: undefined,
  transparent: undefined,
};

export default ClavaDropdown;
