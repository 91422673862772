import React, {
  ChangeEventHandler,
  useCallback,
  useContext,
  useState,
} from "react";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/pro-light-svg-icons";
import { translate } from "../../../../config/translator";
import { ClavaContext } from "../../../../config/contexts";
import { Image as UserImage, MediaCrop } from "../../../../client/api";
import { uploadCloudflare } from "../../../../config/mediaUtils";
import Cropper from "./Cropper";
import ClavaButton from "../../../components/ClavaButton";
import ClavaImage from "../../../components/ClavaImage";
/*
AdPositionEnum.MATCH_HISTORY_BOTTOM: 1080/120
AdPositionEnum.STANDINGS: 1080/120
AdPositionEnum.TOW: 788/260
AdPositionEnum.ELSE: 1080/300
 */
const FileInput: React.FC<{
  preview: UserImage | undefined;
  onChange: (text: UserImage | undefined) => void;
  aspectRatio: number;
  setFileUploadError?: (err: string) => void;
  name: string;
  disabled?: boolean;
}> = ({
  name,
  disabled,
  aspectRatio,
  setFileUploadError,
  preview,
  onChange,
}) => {
  const [progress, setProgress] = useState(0);
  const [file, setFile] = useState<File>();
  const [crop, setCrop] = useState<MediaCrop>();
  const { l } = useContext(ClavaContext);
  const onValueChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      if (!disabled && e.target.files && e.target.files.length === 1) {
        setCrop(undefined);
        setFile(e.target.files[0]);
      }
    },
    [disabled]
  );

  const onUpload = useCallback(() => {
    if (file && crop) {
      const img = new Image();
      const filename = file.name;

      const objectUrl = URL.createObjectURL(file);

      img.onload = () => {
        uploadCloudflare(
          {
            url: objectUrl + JSON.stringify(crop),
            filename,
            file,
            as: img.width / img.height,
          },
          crop,
          (p) => {
            setProgress(Math.round(p * 100));
          }
        ).then(
          (data) => {
            if (data) {
              onChange({
                id: data.id,
                url: data.variants[0],
                aspectRatio: img.width / img.height,
                uploadDate: "",
                cropAspectRatio: crop.cropAspectRatio,
                cropScale: crop.cropScale,
                cropX: crop.cropX,
                cropY: crop.cropY,
              });
              setFile(undefined);
              setCrop(undefined);
            } else if (setFileUploadError)
              setFileUploadError(translate("uploadError", l));
            setProgress(0);
          },
          (error) => {
            setProgress(0);
            if (setFileUploadError) setFileUploadError(error);
          }
        );
      };
      img.src = objectUrl;
    }
  }, [crop, file, l, onChange, setFileUploadError]);
  const reset = useCallback(() => {
    onChange(undefined);
  }, [onChange]);

  return (
    <div className="relative">
      <label htmlFor={name} className="relative">
        {file ? null : preview ? (
          <ClavaImage width="100%" image={preview} />
        ) : (
          <FontAwesomeIcon icon={faImage} className="w-12 h-12" />
        )}
      </label>
      {!!file && (
        <div className="my-2">
          <div className="relative">
            <Cropper file={file} aspectRatio={aspectRatio} setCrop={setCrop} />
            {progress !== 0 && (
              <div
                className="absolute bg-primary-40 top-0 left-0 bottom-0 flex flex-row items-center justify-center text-white transition-all overflow-hidden"
                style={{ width: `${progress}%` }}
              >
                {progress}%
              </div>
            )}
          </div>
          <ClavaButton onClick={onUpload} disabled={!crop}>
            {translate("upload", l)}
          </ClavaButton>
        </div>
      )}

      <div className="relative">
        <input
          tabIndex={0}
          type="file"
          accept="image/*"
          name={name}
          id={name}
          onChange={onValueChange}
          className={`w-full p-2 outline-0 rounded-xl active:bg-input-bg-active active:dark:bg-input-bg-active-dark focus:bg-input-bg-active focus:dark:bg-input-bg-active-dark border-[0.5px] active:dark:border-input-border-active-dark focus:dark:border-input-border-active-dark text-font dark:text-font-dark bg-input-bg dark:bg-input-bg-dark border-input-border dark:border-input-border-dark active:border-input-border-active focus:border-input-border-active placeholder-placeholder dark:placeholder-placeholder-dark ${
            disabled ? "opacity-50" : "opacity-100"
          }`}
        />
        {typeof preview === "string" && preview !== "" && (
          <button
            type="button"
            onClick={reset}
            className="p-1 bottom-1.5 right-3 absolute"
          >
            <FontAwesomeIcon icon={faTrash} className="w-4 h-4" />
          </button>
        )}
      </div>
    </div>
  );
};

FileInput.defaultProps = { disabled: false, setFileUploadError: undefined };

export default FileInput;
