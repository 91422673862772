import React, {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { formatDate } from "../../../config/utils";
import { LanguageLocaleEnum } from "../../../client/api";

function formatDateForHtmlInput(date: Date | undefined): string | undefined {
  if (!date || Number.isNaN(date.getTime())) return undefined;
  return formatDate(date, LanguageLocaleEnum.DE, true, false, true, false);
}

function formatTimeForHtmlInput(date: Date | undefined): string | undefined {
  if (!date || Number.isNaN(date.getTime())) return undefined;
  return `${date.getHours().toString().padStart(2, "0")}:${date
    .getMinutes()
    .toString()
    .padStart(2, "0")}`;
}

const DateInput: React.FC<{
  value: Date;
  onChange: (date: Date) => void;
  disabled?: boolean;
  error?: boolean;
  name: string;
  onFocus?: (index: number | undefined) => void;
  isFocused?: boolean;
  index?: number;
  label: string;
  type: "date" | "datetime" | "time";
}> = ({
  label,
  name,
  index,
  type,
  onFocus,
  error,
  isFocused,
  disabled,
  value,
  onChange,
}) => {
  const date = useRef(formatDateForHtmlInput(value));
  const time = useRef(formatTimeForHtmlInput(value));
  const inputElem = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (isFocused && inputElem.current) inputElem.current.focus();
  }, [isFocused]);
  const onValueChange = useCallback(() => {
    const datestring =
      type === "date"
        ? `${date.current}T12:00:00.000`
        : type === "time"
        ? `2022-01-01T${time.current}:00.000`
        : `${date.current}T${time.current}:00.000`;
    onChange(new Date(datestring));
  }, [onChange, type]);
  const onDateChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      date.current = e.target.value;
      onValueChange();
    },
    [onValueChange]
  );
  const onTimeChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      time.current = e.target.value;
      onValueChange();
    },
    [onValueChange]
  );

  const onFocusCont = useCallback(() => {
    if (onFocus) {
      onFocus(index);
    }
  }, [index, onFocus]);

  const inputClass = useMemo(() => {
    let style =
      "w-full py-2 pl-4 pr-2 outline-0 rounded-xl active:bg-input-bg-active active:dark:bg-input-bg-active-dark focus:bg-input-bg-active focus:dark:bg-input-bg-active-dark border-[0.5px] active:dark:border-input-border-active-dark focus:dark:border-input-border-active-dark text-font dark:text-font-dark";

    if (disabled) {
      style += " border-input-border";
    } else {
      style +=
        " bg-input-bg dark:bg-input-bg-dark border-input-border dark:border-input-border-dark active:border-input-border-active focus:border-input-border-active placeholder-placeholder dark:placeholder-placeholder-dark";
    }
    if (error) {
      style += " !border-red";
    }
    return style;
  }, [disabled, error]);
  return (
    <div className="flex flex-col items-start justify-stretch w-full">
      {!!label && (
        <span
          className={`font-semibold text-sm mb-1 ${error ? "!text-red" : ""}`}
        >
          {label}
        </span>
      )}
      <div className="flex flex-row items-start relative">
        <input
          tabIndex={0}
          disabled={disabled}
          type={type === "datetime" ? "date" : type}
          value={type === "time" ? time.current : date.current}
          name={name}
          id={name}
          className={inputClass}
          ref={inputElem}
          onFocus={onFocusCont}
          onChange={type === "time" ? onTimeChange : onDateChange}
        />

        {type === "datetime" && (
          <div className="ml-4">
            <input
              tabIndex={0}
              onFocus={onFocusCont}
              className={inputClass}
              type="time"
              disabled={disabled}
              value={time.current}
              name={`${name}2`}
              id={`${name}2`}
              onChange={onTimeChange}
            />
          </div>
        )}
      </div>
    </div>
  );
};

DateInput.defaultProps = {
  disabled: false,
  isFocused: false,
  index: undefined,
  error: undefined,
  onFocus: undefined,
};

export default DateInput;
// relo ad
