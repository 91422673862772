import React, { useCallback, useEffect, useMemo, useState } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Crop, PercentCrop, PixelCrop, ReactCrop } from "react-image-crop";
import { Image as UserImage, MediaCrop } from "../../../../../client/api";

// eslint-disable-next-line import/no-extraneous-dependencies
import "react-image-crop/dist/ReactCrop.css";
import ClavaImage from "../../../../components/ClavaImage";

const Cropper: React.FC<{
  file: File;
  aspectRatio: number;
  setCrop: (val: MediaCrop) => void;
}> = ({ file, aspectRatio, setCrop }) => {
  const src = useMemo(() => URL.createObjectURL(file), [file]);
  const [size, setSize] = useState<{ w: number; h: number }>();
  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setSize({ w: img.width, h: img.height });
    };
    img.src = src;
  }, [src]);
  const [cropObj, setCropObj] = useState<Crop>();
  useEffect(() => {
    if (size) {
      const originalAS = size.w / size.h;

      setCropObj({
        width: 100,
        height: 100 * (originalAS / aspectRatio),
        y: 0,
        x: 0,
        unit: "%",
      });
    }
  }, [aspectRatio, size]);
  const onComplete = useCallback(
    (_: PixelCrop, percent: PercentCrop) => {
      setCropObj(percent);
      const originalAS = 100 / percent.height;
      const c: MediaCrop = {
        cropX: (-percent.x / 100) * originalAS,
        cropY: (-percent.y / 100) * originalAS,
        cropScale: percent.width / 100,
        cropAspectRatio: aspectRatio,
      };
      setCrop(c);
    },
    [setCrop, aspectRatio]
  );
  const previewFile = useMemo<UserImage>(() => {
    if (size) {
      const originalAS = 100 / (cropObj?.height ?? 100);
      return {
        url: src,
        uploadDate: "",
        id: "asf",
        aspectRatio: size.w / size.h,
        cropY: (-(cropObj?.y ?? 0) / 100) * originalAS,
        cropX: (-(cropObj?.x ?? 0) / 100) * originalAS,
        cropScale: (cropObj?.width ?? 100) / 100,
        cropAspectRatio: aspectRatio,
      };
    }
    return {
      url: src,
      uploadDate: "",
      id: "asf",
      aspectRatio: 1,
      cropY: -(cropObj?.y ?? 0) / 100,
      cropX: -(cropObj?.x ?? 0) / 100,
      cropScale: (cropObj?.width ?? 100) / 100,
      cropAspectRatio: aspectRatio,
    };
  }, [aspectRatio, cropObj, size, src]);
  return (
    <>
      <ReactCrop onChange={onComplete} aspect={aspectRatio} crop={cropObj}>
        <img src={src} alt="Preview Crop" />
      </ReactCrop>
      <ClavaImage width={256} image={previewFile} className="mt-2" />
    </>
  );
};

export default Cropper;
