import { ConnectedProps } from "react-redux";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ClavaContext } from "../../../../config/contexts";
import { showTranslated, translate } from "../../../../config/translator";
import { connector } from "./redux";
import Loading from "../../../components/Loading";
import client from "../../../../client";
import ClavaTextInput from "../../../components/ClavaTextInput";
import ClavaButton from "../../../components/ClavaButton";
import ClavaDropdown, { DropdownItem } from "../../../components/ClavaDropdown";
import { IDType } from "../../../../config/types";

const AdminpanelAdministration: React.FC<ConnectedProps<typeof connector>> = ({
  createTask,
  status,
  error,
  getAois,
  aois,
}) => {
  const { l, user } = useContext(ClavaContext);
  const [loading, setLoading] = useState<boolean | string>(false);
  const [selectedAoi, setSelectedAoi] = useState<number>(
    user.areaOfInterest.id
  );
  const setSelectedAoiCont = useCallback((id: IDType | undefined) => {
    if (id) setSelectedAoi(id);
  }, []);
  const [selectedMatchday, setSelectedMatchday] = useState<number>();
  const taskSend = useRef(false);
  const onClearCache = useCallback(() => {
    taskSend.current = true;
    createTask();
  }, [createTask]);

  const onSetMatchday = useCallback((e: string) => {
    const n = parseInt(e, 10);
    setSelectedMatchday(Number.isNaN(n) ? undefined : n);
  }, []);
  const onGenerateReport = useCallback(() => {
    setLoading(true);
    client()
      .generateReport(selectedAoi, selectedMatchday)
      .then(() => {
        setLoading("done");
      });
  }, [selectedAoi, selectedMatchday]);
  useEffect(() => {
    getAois();
  }, [getAois]);
  const aoiItems = useMemo<DropdownItem<number>[]>(
    () =>
      aois.map<DropdownItem<number>>((a) => ({
        label: showTranslated(a.name, l),
        key: a.id,
      })),
    [aois, l]
  );
  return (
    <div>
      <fieldset className="overflow-hidden mb-2 p-4 self-stretch flex flex-col items-stretch justify-start rounded-xl bg-bg-secondary dark:bg-bg-secondary-dark shadow-shadow dark:shadow-shadow-dark drop-shadow-sm transition-all max-h-[100000px]">
        {taskSend.current && (
          <>
            {status === "idle" && (
              <span className="text-green">
                {translate("taskCreateSuccess", l)}
              </span>
            )}
            {status === "loading" && <Loading small />}
            {status === "failed" && (
              <span className="text-red">
                {translate("taskCreateFailed", l)}
                <br />
                {error}
              </span>
            )}
          </>
        )}

        <div className="py-2 flex flex-col items-center">
          <ClavaButton onClick={onClearCache}>
            {translate("clearCache", l)}
          </ClavaButton>
        </div>
        <div className="py-2">
          <h3 className="font-semibold text-lg">Playerscore</h3>

          {loading === "done" && (
            <span>
              {translate("reportGenerating", l, {
                "[mail]": `${user.email}`,
              })}
            </span>
          )}
        </div>
        {loading !== true ? (
          <>
            <div className="py-2">
              <ClavaDropdown
                value={selectedAoi}
                onChange={setSelectedAoiCont}
                withoutChoose
                items={aoiItems}
              />
            </div>
            <div className="py-2">
              <ClavaTextInput
                type="number"
                placeholder={translate("matchDay", l)}
                value={selectedMatchday?.toString()}
                onChange={onSetMatchday}
              />
            </div>
            <div className="py-2">
              <ClavaButton onClick={onGenerateReport} size="full">
                {translate("generateReport", l)}
              </ClavaButton>
            </div>
          </>
        ) : (
          <Loading small />
        )}
      </fieldset>
    </div>
  );
};

export default connector(AdminpanelAdministration);
// rel
