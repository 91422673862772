import * as Sentry from "@sentry/react";
import { DateTimeFormat } from "intl";
import {
  browserName,
  isMobile,
  mobileModel,
  mobileVendor,
  osName,
  osVersion,
} from "react-device-detect";
import {
  CollectionType,
  Favorite,
  FavoriteType,
  IdentifierType,
  IDType,
  LanguageISO,
  MatchSmallElement,
} from "./types";
import {
  CardEvent,
  CardTypeEnum,
  ChangeEvent,
  EventType,
  EventTypeEnum,
  GoalEvent,
  GroupEnum,
  GroupStageMinimal,
  Image,
  LanguageLocaleEnum,
  League,
  LeagueListElement,
  MatchLeague,
  MatchListElement,
  MatchSmall,
  MatchTournamentGroup,
  MatchTournamentMinimal,
  MatchTournamentStage,
  ScopeEnum,
  SingleLeagueMatch,
  SportEnum,
  Tournament,
  TournamentGroup,
  TournamentMinimal,
  TournamentStage,
  Translation,
  User,
} from "../client/api";

export function isLocalDev() {
  return !process.env.NODE_ENV || process.env.NODE_ENV === "development";
}

export function errorLogging(
  error: object | string,
  type?: Sentry.Severity
): void {
  if (isLocalDev()) {
    console.log(JSON.stringify(error));
    console.trace("Error at: ");
  } else {
    Sentry.addBreadcrumb({
      level: type ?? Sentry.Severity.Warning,
      data: { error },
    });
  }
}
const SIZES = [128, 256, 512, 1080, 1920];
export function nearestSize(w: number): string {
  for (let i = SIZES.length - 1; i >= 0; i--) {
    if (w > SIZES[i]) {
      return `/w=${SIZES[i]}`;
    }
  }
  return "";
}

export function generatePW(length = 12, bigCharset = true): string {
  let charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ01234567890123456789";
  if (bigCharset) {
    charset += "-_#+*!§$%&/()=?ß{[]}";
  }
  let retVal = "";
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
}

export const HOUR_IN_MS = 60000 * 60;
export const MINUTE_IN_MS = 60000;
export const DAY_IN_MS = HOUR_IN_MS * 24;

export function removeDuplicateMatchDays(dates: Date[]): Date[] {
  return dates
    .map((date) => date.getTime())
    .filter((date, index, arr) => arr.indexOf(date) === index)
    .map((date) => new Date(date));
}

export function intlLang(l: LanguageISO): string {
  return l === "de" ? "de-DE" : l === "it" ? "it-IT" : "en-US";
}

export function formatDate(
  date: Date | string,
  l: LanguageISO,
  forInput = false,
  onlyTime = false,
  onlyDate = false,
  long = false
): string {
  const d = new Date(date);
  if (typeof d === "undefined") {
    return "";
  }
  if (forInput) {
    const iso = d.toISOString();
    if (onlyDate) return iso.split("T")[0];
    if (onlyTime) return iso.split("T")[1];
    return iso;
  }
  let options: Intl.DateTimeFormatOptions;
  if (onlyTime) {
    options = {
      hour: "2-digit",
      minute: "2-digit",
    };
    if (long) options.second = "2-digit";
    return d.toLocaleTimeString(intlLang(l), options);
  }
  if (onlyDate) {
    options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    if (long) options.weekday = "long";
  } else {
    options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };
  }

  return DateTimeFormat(intlLang(l), options).format(d);
}

export function dayToNumber(date: Date): number {
  return date.getFullYear() * 10000 + date.getMonth() * 100 + date.getDate();
}

export function numberToDay(date: number): Date {
  const year = Math.floor(date / 10000);
  const month = Math.floor((date - year * 10000) / 100);
  const day = Math.floor(date - year * 10000 - month * 100);
  return new Date(
    `${year}-${(month + 1).toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`
  );
}

export function sameDay(date1: Date | number, date2: Date): boolean {
  if (typeof date1 === "number" || date1 === undefined) return false;
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  );
}

type MatchBasic = {
  alternativeStartTime?: string | null;
  originalStartTime: string;
};
export function getMatchDate<T extends MatchBasic>(match: T): Date {
  return match.alternativeStartTime
    ? new Date(match.alternativeStartTime)
    : new Date(match.originalStartTime);
}
export function getMatchDateFast(
  alternativeStartTime: string | undefined | null,
  originalStartTime: string
): Date {
  return alternativeStartTime
    ? new Date(alternativeStartTime)
    : new Date(originalStartTime);
}

export function sortMatchesByTimeFast(
  a: MatchSmallElement,
  b: MatchSmallElement
): number {
  return a.startTime - b.startTime;
}

export function isGoalEvent(event: EventType | undefined): event is GoalEvent {
  return !!event && event.type === EventTypeEnum.GOAL;
}

export function isCardEvent(event: EventType | undefined): event is CardEvent {
  return !!event && event.type === EventTypeEnum.CARD;
}

export function isChangeEvent(
  event: EventType | undefined
): event is ChangeEvent {
  return !!event && event.type === EventTypeEnum.CHANGE;
}

const mailReg =
  /^[a-zA-Z0-9!#$%&'*+/=?^`{}|~_-]+[.a-zA-Z0-9!#$%&'*+/=?^`{}|~_-]*@[a-zA-Z0-9]+[._a-zA-Z0-9-]*\.[a-zA-Z0-9]+$/i;

export function checkMail(email: string): boolean {
  return mailReg.test(email);
}

export function isAdmin(user: User | undefined | null): boolean {
  return !!(
    user && !!user.scopes.find((scope) => scope.key === ScopeEnum.ADMIN)
  );
}

export function isInsider(
  user: User | undefined | null,
  teamID?: IDType
): boolean {
  return (
    isAdmin(user) ||
    !!(
      user &&
      user.groups.find(
        (group) =>
          (group.key === GroupEnum.INSIDER ||
            group.key === GroupEnum.TEAM_INSIDER) &&
          (!teamID || group.team.id === teamID)
      )
    )
  );
}

export function isTeamInsider(
  user: User | undefined | null,
  teamID?: IDType
): boolean {
  return (
    isAdmin(user) ||
    !!(
      user &&
      user.groups.find(
        (group) =>
          group.key === GroupEnum.TEAM_INSIDER &&
          (!teamID || group.team.id === teamID)
      )
    )
  );
}

export function isPremium(user: User | null | undefined): boolean {
  return !!user && user.premium /* || isAdmin(user)*/;
}

export function isRegistered(user: User | undefined | null): boolean {
  return !!(user && !user.anonymous);
}

export function unique<T extends CollectionType>(elements: T[]): T[] {
  const ids = elements.map((e) => e.id);
  return elements.filter((e, idx) => ids.indexOf(e.id) === idx);
}

export function numberedHash(str: string): number {
  let hash = 0;
  let i;
  let chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + chr;
    // eslint-disable-next-line no-bitwise
    hash |= 0; // Convert to 32bit in teger
  }
  return hash;
}

export function replaceOrAddResponseSingle<T extends CollectionType>(
  elem: T,
  storeArray: T[],
  cmpFunc?: (a: T, b: T) => boolean
): T[] {
  let found = false;
  return storeArray
    .map((e) => {
      if (cmpFunc ? cmpFunc(e, elem) : e.id === elem.id) {
        found = true;
        return elem;
      }
      return e;
    })
    .concat(found ? [] : [elem]);
}

export function replaceOrAddResponseMultiple<T extends CollectionType>(
  elems: T[],
  storeArray: T[]
): T[] {
  const found: IDType[] = [];
  return storeArray
    .map((e) => {
      const elem = elems.find((el) => el.id === e.id);
      if (elem) {
        found.push(elem.id);
        return elem;
      }
      return e;
    })
    .concat(elems.filter((e) => found.indexOf(e.id) === -1));
}

export function getChangeEvents(
  events: EventType[],
  teamId?: IDType
): ChangeEvent[] {
  return events.filter(
    (e) => isChangeEvent(e) && (!teamId || e.teamId === teamId)
  ) as ChangeEvent[];
}

export function getCardEvents(
  events: EventType[],
  teamId?: IDType,
  cardTypes?: CardTypeEnum[]
): CardEvent[] {
  return events.filter(
    (e) =>
      isCardEvent(e) &&
      (!teamId || e.teamId === teamId) &&
      (!cardTypes || cardTypes.indexOf(e.cardType.key) === -1)
  ) as CardEvent[];
}
export function getGoalEvents(
  events: EventType[],
  teamId?: IDType
): GoalEvent[] {
  return events.filter(
    (e) => isGoalEvent(e) && (!teamId || e.teamId === teamId)
  ) as GoalEvent[];
}

export function getClosestDate(dates: Date[]): Date {
  const nowDate = new Date();
  nowDate.setHours(5);
  const now = nowDate.getTime();
  const differences = dates.map((d) => Math.abs(d.getTime() - now));
  const closest = Math.min(...differences);
  const idx = differences.indexOf(closest);
  if (idx === -1) return dates[0];
  return dates[idx];
}

export function isFavorite(
  favorites: Favorite[],
  id: IDType,
  type: FavoriteType,
  team1Id: IDType,
  team2Id: IDType
): boolean {
  return !!favorites.find(
    (fav) =>
      (fav.id === id && fav.type === type) ||
      (fav.type === "team" && fav.id === team1Id) ||
      (fav.type === "team" && fav.id === team2Id)
  );
}

export function getDeviceInfo(): Record<string, string> {
  return {
    brand: isMobile ? mobileVendor : osName,
    buildNumber: "",
    model: isMobile ? mobileModel : osVersion,
    modelAlt: browserName,
    systemName: osName,
    systemVersion: osVersion,
    version: "",
    type: isMobile ? "Mobile" : "Desktop",
    userAgent: navigator.userAgent,
  };
}

export function browserLang(): LanguageLocaleEnum {
  return navigator.language === LanguageLocaleEnum.IT ||
    navigator.language === LanguageLocaleEnum.DE
    ? navigator.language
    : LanguageLocaleEnum.EN;
}

export function sortEventsMinute(e1: EventType, e2: EventType): number {
  return (e1.minute ?? 99) - (e2.minute ?? 99);
}

export function getMainLeague<T extends League | LeagueListElement>(
  leagues: T[]
): T | undefined {
  let max = 0;
  leagues.forEach((l) => {
    if (l.main && l.year > max) max = l.year;
  });
  return leagues.find((l) => l.main && l.year === max);
}

export function emailOrTel(text: string): IdentifierType {
  if (text.match(/\+[0-9]+|[0-9]+$/gm)) {
    return "tel";
  }
  if (text.match(/[a-zA-Z@.]+/gm)) {
    return "mail";
  }
  return "both";
}

export const MIN_AD_DISTANCE = 8;
export const MAX_AD_DISTANCE = 20;

export function shouldAdBePlaced(
  matchCounter: number,
  numOfAds: number
): boolean {
  return (
    numOfAds === 0 ||
    (matchCounter > MIN_AD_DISTANCE &&
      matchCounter - MIN_AD_DISTANCE > Math.random() * MAX_AD_DISTANCE * 3)
  );
}

export function filterTranslatable(name: Translation, q: string) {
  return (
    name.text.toLowerCase().indexOf(q.toLowerCase()) !== -1 ||
    name.textIT.toLowerCase().indexOf(q.toLowerCase()) !== -1 ||
    name.textEN.toLowerCase().indexOf(q.toLowerCase()) !== -1 ||
    name.textDE.toLowerCase().indexOf(q.toLowerCase()) !== -1
  );
}

export function setHead(title: string) {
  document.title = `Clava Sports - ${title}`;
}

/**
 * Return true if match done, flase if match not startet or the number of minutes passed since start
 * @param match
 * @param fullMatchLength
 */
export function matchStatus(
  match:
    | MatchLeague
    | MatchListElement
    | MatchSmallElement
    | MatchTournamentStage
    | MatchTournamentGroup,
  fullMatchLength?: number
): MATCH_STATUS {
  if ("startTime" in match) {
    return matchStatusDate(
      match.startTime,
      match.fullMatchLength,
      match.endTime
    );
  }
  const diffMinutes = calcMinute(match);
  const now = new Date().getTime();
  const endTime = new Date(match.endTime).getTime();
  if (diffMinutes < 0) {
    return "future";
  }
  if (now > endTime) {
    return "past";
  }
  const ml = fullMatchLength ?? fullMatchLengthMinutes(match);
  if (diffMinutes > ml) {
    return "ot";
  }
  return diffMinutes;
}
/**
 * Return past if match done, ot if in OT, future if match not startet or the number of minutes passed
 * @param startDate
 * @param matchLength
 * @param endTime
 */
export function matchStatusDate(
  startDate: number,
  matchLength: number,
  endTime: number
): MATCH_STATUS {
  const now = new Date().getTime();
  const diffMinutes = Math.ceil((now - startDate) / 60000);
  if (diffMinutes < 0) {
    return "future";
  }
  if (now > endTime) {
    return "past";
  }
  if (diffMinutes > matchLength) {
    return "ot";
  }
  return diffMinutes;
}

export function isLive(
  match: MatchLeague | MatchListElement | MatchSmallElement
): boolean {
  const stat =
    "startTime" in match
      ? matchStatusDate(match.startTime, match.fullMatchLength, match.endTime)
      : matchStatus(match);
  return typeof stat === "number" || stat === "ot";
}

export declare type MATCH_STATUS = "future" | "past" | number | "ot";

export function calcMinute(
  match:
    | MatchLeague
    | MatchListElement
    | MatchTournamentStage
    | MatchTournamentGroup
    | undefined
    | null
): number {
  if (match) {
    const now = new Date();
    return Math.ceil(
      (now.getTime() - getMatchDate(match).getTime()) / MINUTE_IN_MS
    );
  }
  return 0;
}
type MatchLengthEssential = {
  league: {
    matchSectionAmount: number;
    matchSectionDurationMinutes: number;
    matchSectionPauseDurationMinutes: number;
  };
};

export function fullMatchLengthMinutesRaw(
  matchSectionAmount: number,
  matchSectionDurationMinutes: number,
  matchSectionPauseDurationMinutes: number
) {
  return (
    matchLengthMinutesRaw(matchSectionAmount, matchSectionDurationMinutes) +
    (matchSectionAmount - 1) * matchSectionPauseDurationMinutes
  );
}
export function fullMatchLengthMinutes(
  match:
    | MatchLeague
    | MatchListElement
    | MatchLengthEssential
    | MatchTournamentGroup
    | MatchTournamentStage
    | undefined
    | null
): number {
  if (match) {
    if ("league" in match) {
      return fullMatchLengthMinutesRaw(
        match.league.matchSectionAmount,
        match.league.matchSectionDurationMinutes,
        match.league.matchSectionPauseDurationMinutes
      );
    }
    return fullMatchLengthMinutesCup(
      "tournamentStage" in match ? match.tournamentStage : match.tournamentGroup
    );
  }
  return -1;
}
export function fullMatchLengthMinutesCup(
  stage: GroupStageMinimal | TournamentStage | TournamentGroup
): number {
  return fullMatchLengthMinutesRaw(
    stage.matchSectionAmount,
    stage.matchSectionDurationMinutes,
    stage.matchSectionPauseDurationMinutes
  );
}
export function matchLengthMinutesRaw(
  matchSectionAmount: number,
  matchSectionDurationMinutes: number
) {
  return matchSectionAmount * matchSectionDurationMinutes;
}

export function getSectionsPassed(
  minute: number,
  sectionAmount: number,
  sectionDuration: number,
  pauseDuration: number
): number {
  let sectionsPassed = 0;
  for (let i = 1; i <= sectionAmount; i++) {
    if (
      minute >= fullMatchLengthMinutesRaw(i, sectionDuration, pauseDuration)
    ) {
      sectionsPassed = i;
    }
  }
  return sectionsPassed;
}
export function actualMatchMinute(
  minute: number,
  sectionAmount: number,
  sectionDuration: number,
  pauseDuration: number
): number {
  const duration = matchLengthMinutesRaw(sectionAmount, sectionDuration);
  const sectionsPassed = getSectionsPassed(
    minute,
    sectionAmount,
    sectionDuration,
    pauseDuration
  );
  const timePassed = fullMatchLengthMinutesRaw(
    sectionsPassed,
    sectionDuration,
    pauseDuration
  );
  const timePassedPlaying = timePassed - (sectionsPassed - 1) * pauseDuration;
  const inPause = minute - timePassed < pauseDuration;
  return Math.max(
    1,
    Math.min(
      duration + 1,
      inPause ? timePassedPlaying : minute - sectionsPassed * pauseDuration
    )
  );
}

export function isContentManager(user: User | undefined | null) {
  return (
    isAdmin(user) ||
    !!(
      user &&
      !!user.scopes.find((scope) => scope.key === ScopeEnum.CONTENT_MANAGER)
    )
  );
}

export function getElementLayout(elem: HTMLElement, id: string): DOMRect {
  let foundElem: HTMLElement = elem;
  let i = 0;
  while (foundElem.id !== id && i < 100) {
    if (elem.parentElement === null) break;
    foundElem = elem.parentElement;
    i++;
  }
  return foundElem.getBoundingClientRect();
}

export const emptyTranslation: Translation = {
  id: -1,
  text: "",
  textDE: "",
  textIT: "",
  textEN: "",
};
export const emptyEmblem: Image = {
  id: "32de1ae1-ea19-4074-6420-57a8bf9ab600",
  url: "https://imagedelivery.net/l1rEUf9lP788c6Wug0LUJw/32de1ae1-ea19-4074-6420-57a8bf9ab600/public",
  uploadDate: new Date().toISOString(),
  aspectRatio: 1,
};
export function mapToMatchSmallSmall(match: MatchSmall): MatchSmallElement {
  const matchDate = new Date(match.startTime);
  return {
    id: match.id,
    goal1: match.goal1,
    goal2: match.goal2,
    startTime: matchDate.getTime(),
    endTime: new Date(match.endTime).getTime(),
    dayAsNumber: dayToNumber(matchDate),
    fullMatchLength: fullMatchLengthMinutesRaw(
      match.sectionAmount,
      match.sectionDuration,
      match.pauseDuration
    ),
    reducedMatchLength: matchLengthMinutesRaw(
      match.sectionAmount,
      match.sectionDuration
    ),
    sectionAmount: match.sectionAmount,
    sectionDuration: match.sectionDuration,
    pauseDuration: match.pauseDuration,
    type: match.matchType,
    leagueCupId: match.leagueTournamentId,
    leagueCupName: match.leagueTournamentName,
    cancelled: match.cancelled,
    sports: match.sports,
    team1: {
      id: match.team1?.id ?? -1,
      name: match.team1?.name ?? emptyTranslation,
      emblemUrl: match.team1?.emblemUrl ?? emptyEmblem.url,
    },
    team2: {
      id: match.team2?.id ?? -1,
      name: match.team2?.name ?? emptyTranslation,
      emblemUrl: match.team2?.emblemUrl ?? emptyEmblem.url,
    },
  };
}

export function toMatchTournamentMinimal(
  match: MatchTournamentGroup | MatchTournamentStage
): MatchTournamentMinimal {
  return {
    originalStartTime: match.originalStartTime,
    id: match.id,
    matchType: match.matchType,
    team1: match.team1,
    team2: match.team2,
    team1Placeholder: match.team1Placeholder,
    team2Placeholder: match.team2Placeholder,
    goal1: match.goal1,
    goal2: match.goal2,
    alternativeStartTime: match.alternativeStartTime,
    endTime: match.endTime,
    cancelled: match.cancelled,
    nextMatchId: match.nextMatchId,
    location: match.location,
  };
}
export function mapToMatchListElement(match: MatchLeague): MatchListElement {
  return {
    id: match.id,
    goal1: match.goal1,
    goal2: match.goal2,
    league: match.league,
    endTime: match.endTime,
    alternativeStartTime: match.alternativeStartTime,
    originalStartTime: match.originalStartTime,
    matchType: match.matchType,
    matchDay: match.matchDay,
    cancelled: match.cancelled,
    team1: match.team1,
    team2: match.team2,
    lineupTeam1Id: match.lineupTeam1Id,
    lineupTeam2Id: match.lineupTeam2Id,
  };
}
export function mapToMatchSmall(
  match:
    | MatchListElement
    | MatchLeague
    | MatchTournamentGroup
    | MatchTournamentStage
    | MatchTournamentMinimal
    | SingleLeagueMatch,
  leagueOrCup: League | LeagueListElement | Tournament | TournamentMinimal
): MatchSmallElement {
  const matchDate = getMatchDate(match);
  const startTime = matchDate.getTime();
  const endTime = new Date(match.endTime).getTime();
  const length = Math.floor((endTime - startTime) / 60000);
  return {
    id: match.id,
    goal1: match.goal1,
    goal2: match.goal2,
    startTime,
    endTime,
    dayAsNumber: dayToNumber(matchDate),
    fullMatchLength: leagueOrCup
      ? fullMatchLengthMinutesRaw(
          leagueOrCup.matchSectionAmount,
          leagueOrCup.matchSectionDurationMinutes,
          leagueOrCup.matchSectionPauseDurationMinutes
        )
      : length,
    reducedMatchLength: leagueOrCup
      ? matchLengthMinutesRaw(
          leagueOrCup.matchSectionAmount,
          leagueOrCup.matchSectionDurationMinutes
        )
      : length,
    sectionAmount:
      "tournamentStage" in match
        ? match.tournamentStage.matchSectionAmount
        : leagueOrCup.matchSectionAmount,
    sectionDuration:
      "tournamentStage" in match
        ? match.tournamentStage.matchSectionDurationMinutes
        : leagueOrCup.matchSectionDurationMinutes,
    pauseDuration:
      "tournamentStage" in match
        ? match.tournamentStage.matchSectionPauseDurationMinutes
        : leagueOrCup.matchSectionPauseDurationMinutes,
    type: match.matchType,
    leagueCupId: leagueOrCup ? leagueOrCup.id : -1,
    leagueCupName: leagueOrCup ? leagueOrCup.name : emptyTranslation,
    cancelled: "cancelled" in match ? match.cancelled : false,
    sports: leagueOrCup ? leagueOrCup.sports : SportEnum.SOCCER,
    team1: {
      id: match.team1?.id ?? -1,
      name: match.team1
        ? match.team1.name
        : "team1Placeholder" in match
        ? {
            id: -1,
            text: match.team1Placeholder,
            textDE: match.team1Placeholder,
            textIT: match.team1Placeholder,
            textEN: match.team1Placeholder,
          }
        : emptyTranslation,
      emblemUrl: match.team1 ? match.team1.emblem.url : emptyEmblem.url,
    },
    team2: {
      id: match.team2?.id ?? -1,
      name: match.team2
        ? match.team2.name
        : "team1Placeholder" in match
        ? {
            id: -1,
            text: match.team2Placeholder,
            textDE: match.team2Placeholder,
            textIT: match.team2Placeholder,
            textEN: match.team2Placeholder,
          }
        : emptyTranslation,
      emblemUrl: match.team2 ? match.team2.emblem.url : emptyEmblem.url,
    },
  };
}
