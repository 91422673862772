import React, { useMemo } from "react";

type ClavaButtonProps = {
  secondary?: boolean;
  disabled?: boolean;
  size?: "fit" | "full";
  onClick: () => void;
};

const ClavaButton: React.FC<React.PropsWithChildren<ClavaButtonProps>> = ({
  onClick,
  secondary,
  disabled,
  size,
  children,
}) => {
  const btnClasses = useMemo(() => {
    let style = "";
    if (disabled) {
      style +=
        "rounded-[15px] py-2 px-8 flex flex-row items-center justify-center border-0 text-center bg-transparent text-primary opacity-60";
    } else if (secondary)
      style +=
        "rounded-[15px] py-2 px-8 flex flex-row items-center justify-center border-primary text-center bg-transparent active:bg-btn-active hover:bg-btn-active text-primary active:text-font-dark hover:text-font-dark";
    else
      style +=
        "rounded-[15px] py-2 px-8 flex flex-row items-center justify-center border-0 bg-primary text-center active:bg-btn-active hover:bg-btn-active text-font-dark";
    if (size === "full") {
      style += " w-full";
    } else {
      style += " w-fit";
    }
    return style;
  }, [disabled, size, secondary]);
  return (
    <button className={btnClasses} onClick={onClick} type="button">
      {children}
    </button>
  );
};

ClavaButton.defaultProps = {
  secondary: undefined,
  size: "fit",
  disabled: undefined,
};

export default ClavaButton;
