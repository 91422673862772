import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { connect } from "react-redux";
import { RootState } from "../../../../store";
import { createTask } from "../../../../store/actions/adminActions";
import { performAction } from "../../../../store/actions/all";
import { fetchAois } from "../../../../store/actions/aoiActions";

const mapper = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  createTask: () => {
    performAction({ f: createTask, p: [dispatch] });
  },
  getAois: () => {
    performAction({ f: fetchAois, p: [dispatch] });
  },
});

const props = (state: RootState) => ({
  status: state.admin.status,
  error: state.admin.error,
  aois: state.aois.value,
});

export const connector = connect(props, mapper);
