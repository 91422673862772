import React, { useCallback, useMemo, useState } from "react";

const ClavaSwitch: React.FC<{
  disabled?: boolean;
  label?: string;
  horizontal?: boolean;
  onText?: string;
  offText?: string;
  error?: boolean;
  value?: boolean;
  onChange: (val: boolean) => void;
}> = ({
  onText,
  offText,
  horizontal,
  disabled,
  label,
  value,
  onChange,
  error,
}) => {
  const [val, setVal] = useState(value);
  const switchClass = useMemo(() => {
    let style =
      "rounded-full border border-primary flex flex-row items-center justify-between p-0.5 bg-transparent border-input-border dark:border-input-border-dark";

    if (disabled) {
      style += " opacity-50";
    }
    return style;
  }, [disabled]);
  const realOnText = useMemo(() => {
    if (onText) return onText;
    return "ON";
  }, [onText]);
  const realOffText = useMemo(() => {
    if (offText) return offText;
    return "OFF";
  }, [offText]);
  const onToggle = useCallback(() => {
    onChange(!val);
    setVal(!val);
  }, [onChange, val]);
  return (
    <button
      className={`relative flex w-full ${
        horizontal
          ? "flex-row items-center justify-between"
          : "flex-col items-start justify-stretch"
      }`}
      onClick={onToggle}
      type="button"
    >
      {!!label && (
        <span
          className={`font-semibold text-sm ${horizontal ? "" : "mb-1"} ${
            error ? "!text-red" : ""
          }`}
        >
          {label}
        </span>
      )}
      <div className={switchClass}>
        <div
          className={`rounded-full flex flex-col items-center font-semibold justify-center text-xs w-7 h-7 transition-all ${
            val
              ? "mr-0 ml-6 bg-primary dark:bg-primary text-white"
              : "border border-light-gray dark:border-light-gray-dark bg-input-bg dark:bg-input-bg-dark ml-0 mr-6"
          }`}
        >
          {val ? realOnText : realOffText}
        </div>
      </div>
    </button>
  );
};

ClavaSwitch.defaultProps = {
  disabled: undefined,
  label: undefined,
  horizontal: undefined,
  value: undefined,
  onText: undefined,
  offText: undefined,
  error: undefined,
};

export default ClavaSwitch;
