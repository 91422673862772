import { Dispatch } from "redux";
import client from "../../client";
import {
  AdminActions,
  AdminActionTypes,
  SEARCH_ADS,
  SEARCH_LEAGUES,
  SEARCH_LOCATION,
  SEARCH_MATCH,
  SEARCH_TEAMS,
  SEARCH_TYPES,
  SEARCH_USERS,
} from "./types";
import { defaultGet } from "./all";
import { IDType } from "../../config/types";
import {
  AdCreate,
  AdPatch,
  BadgeCreate,
  BadgePatch,
  BadgeTypeEnum,
  BlogCreate,
  LeagueCreate,
  LeaguePatch,
  MatchImport,
  MatchLeagueCreateNew,
  MatchLeaguePatch,
  UserBadgeCreateDelete,
} from "../../client/api";
import { MatchFilterType } from "../../views/screens/Adminpanel/AdminMatch/types";

export function getMatch(dispatch: Dispatch<AdminActions>, matchId: IDType) {
  defaultGet(
    dispatch,
    AdminActionTypes.FETCH_MATCH_SUCCESS,
    AdminActionTypes.FETCH_ERROR,
    AdminActionTypes.FETCH_MATCH,
    client().getMatch,
    false,
    false,
    matchId
  );
}

export function getLeague(dispatch: Dispatch<AdminActions>, leagueId: IDType) {
  defaultGet(
    dispatch,
    AdminActionTypes.FETCH_LEAGUE_SUCCESS,
    AdminActionTypes.FETCH_ERROR,
    AdminActionTypes.FETCH_LEAGUE,
    client().getLeague,
    false,
    false,
    leagueId
  );
}

export function getAd(dispatch: Dispatch<AdminActions>, adId: IDType) {
  defaultGet(
    dispatch,
    AdminActionTypes.FETCH_AD_SUCCESS,
    AdminActionTypes.FETCH_ERROR,
    AdminActionTypes.FETCH_AD,
    client().getAd,
    false,
    false,
    adId
  );
}

export function createLeague(
  dispatch: Dispatch<AdminActions>,
  league: LeagueCreate
) {
  defaultGet(
    dispatch,
    AdminActionTypes.FETCH_LEAGUE_SUCCESS,
    AdminActionTypes.FETCH_ERROR,
    AdminActionTypes.FETCH_LEAGUE,
    client().createLeague,
    false,
    false,
    league
  );
}

export function deleteLeague(dispatch: Dispatch<AdminActions>, id: IDType) {
  defaultGet(
    dispatch,
    AdminActionTypes.FETCH_LEAGUE_SUCCESS,
    AdminActionTypes.FETCH_ERROR,
    AdminActionTypes.FETCH_LEAGUE,
    client().deleteLeague,
    false,
    false,
    id
  );
}

export function patchLeague(
  dispatch: Dispatch<AdminActions>,
  id: IDType,
  league: LeaguePatch
) {
  defaultGet(
    dispatch,
    AdminActionTypes.FETCH_LEAGUE_SUCCESS,
    AdminActionTypes.FETCH_ERROR,
    AdminActionTypes.FETCH_LEAGUE,
    client().patchLeague,
    false,
    false,
    id,
    league
  );
}
export function duplicateLeague(
  dispatch: Dispatch<AdminActions>,
  id: IDType,
  year: number,
  deactivateOld: boolean
) {
  defaultGet(
    dispatch,
    AdminActionTypes.FETCH_LEAGUE_SUCCESS,
    AdminActionTypes.FETCH_ERROR,
    AdminActionTypes.FETCH_LEAGUE,
    client().duplicateLeague,
    false,
    false,
    id,
    year,
    deactivateOld
  );
}

export function createMatch(
  dispatch: Dispatch<AdminActions>,
  leagueId: IDType,
  match: MatchLeagueCreateNew
) {
  defaultGet(
    dispatch,
    AdminActionTypes.FETCH_MATCH_SUCCESS,
    AdminActionTypes.FETCH_ERROR,
    AdminActionTypes.FETCH_MATCH,
    client().createMatch,
    false,
    false,
    leagueId,
    match
  );
}

export function deleteMatch(dispatch: Dispatch<AdminActions>, id: IDType) {
  defaultGet(
    dispatch,
    AdminActionTypes.DELETE_MATCH_SUCCESS,
    AdminActionTypes.FETCH_ERROR,
    AdminActionTypes.FETCH_MATCH,
    client().deleteMatch,
    false,
    false,
    id
  );
}

export function createMatchMultiple(
  dispatch: Dispatch<AdminActions>,
  leagueId: IDType,
  match: MatchLeagueCreateNew[]
) {
  defaultGet(
    dispatch,
    AdminActionTypes.PATCH_MATCH_SUCCESS,
    AdminActionTypes.FETCH_ERROR,
    AdminActionTypes.FETCH_MATCH,
    client().createMatchMultiple,
    false,
    false,
    leagueId,
    match
  );
}

export function csvImportMatch(
  dispatch: Dispatch<AdminActions>,
  matches: MatchImport[],
  dryRun: boolean
) {
  defaultGet(
    dispatch,
    AdminActionTypes.MATCH_IMPORT_SUCCESS,
    AdminActionTypes.FETCH_ERROR,
    AdminActionTypes.MATCH_IMPORT,
    client().csvImportMatch,
    false,
    false,
    matches,
    dryRun
  );
}

export function patchMatch(
  dispatch: Dispatch<AdminActions>,
  id: IDType,
  match: MatchLeaguePatch
) {
  defaultGet(
    dispatch,
    AdminActionTypes.FETCH_MATCH_SUCCESS,
    AdminActionTypes.FETCH_ERROR,
    AdminActionTypes.FETCH_MATCH,
    client().patchMatch,
    false,
    false,
    id,
    match
  );
}

export function patchAd(
  dispatch: Dispatch<AdminActions>,
  id: IDType,
  ad: AdPatch
) {
  defaultGet(
    dispatch,
    AdminActionTypes.FETCH_AD_SUCCESS,
    AdminActionTypes.FETCH_ERROR,
    AdminActionTypes.FETCH_AD,
    client().patchAd,
    false,
    false,
    id,
    ad
  );
}

export function createAd(dispatch: Dispatch<AdminActions>, ad: AdCreate) {
  defaultGet(
    dispatch,
    AdminActionTypes.FETCH_AD_SUCCESS,
    AdminActionTypes.FETCH_ERROR,
    AdminActionTypes.FETCH_AD,
    client().createAd,
    false,
    false,
    ad
  );
}

export function deleteAd(dispatch: Dispatch<AdminActions>, id: IDType) {
  defaultGet(
    dispatch,
    AdminActionTypes.FETCH_AD_SUCCESS,
    AdminActionTypes.FETCH_ERROR,
    AdminActionTypes.FETCH_AD,
    client().deleteAd,
    false,
    false,
    id
  );
}

// reload

export function searchAdmin(
  dispatch: Dispatch<AdminActions>,
  q: string,
  type: SEARCH_TYPES
) {
  defaultGet(
    dispatch,
    AdminActionTypes.SEARCH_SUCCESS,
    AdminActionTypes.FETCH_ERROR,
    AdminActionTypes.SEARCH,
    type === SEARCH_USERS
      ? client().searchUser
      : type === SEARCH_LEAGUES
      ? client().searchLeagues
      : type === SEARCH_TEAMS
      ? client().searchTeams
      : type === SEARCH_LOCATION
      ? client().searchLocations
      : type === SEARCH_MATCH
      ? client().searchMatches
      : type === SEARCH_ADS
      ? client().searchAds
      : client().searchLeagues,
    false,
    { id: type },
    q.length === 0 ? " " : q,
    0,
    100
  );
}

export function searchMatchFiltered(
  dispatch: Dispatch<AdminActions>,
  q: string,
  filters: MatchFilterType[],
  limit: number,
  offset: number
) {
  defaultGet(
    dispatch,
    AdminActionTypes.SEARCH_SUCCESS,
    AdminActionTypes.FETCH_ERROR,
    AdminActionTypes.SEARCH,
    client().searchMatchesFiltered,
    false,
    { id: SEARCH_MATCH },
    q.length === 0 ? " " : q,
    filters,
    limit,
    offset
  );
}

export function addTeamToLeague(
  dispatch: Dispatch<AdminActions>,
  leagueId: IDType,
  teamIds: IDType[]
) {
  defaultGet(
    dispatch,
    AdminActionTypes.FETCH_LEAGUE_SUCCESS,
    AdminActionTypes.FETCH_ERROR,
    AdminActionTypes.FETCH_LEAGUE,
    client().addTeamToLeague,
    false,
    false,
    leagueId,
    teamIds
  );
}

export function removeTeamFromLeague(
  dispatch: Dispatch<AdminActions>,
  leagueId: IDType,
  teamIds: IDType[]
) {
  defaultGet(
    dispatch,
    AdminActionTypes.FETCH_LEAGUE_SUCCESS,
    AdminActionTypes.FETCH_ERROR,
    AdminActionTypes.FETCH_LEAGUE,
    client().removeTeamFromLeague,
    false,
    false,
    leagueId,
    teamIds
  );
}

export function createTask(dispatch: Dispatch<AdminActions>) {
  defaultGet(
    dispatch,
    AdminActionTypes.CREATE_TASK_SUCCESS,
    AdminActionTypes.FETCH_ERROR,
    AdminActionTypes.CREATE_TASK,
    client().clearCache,
    false,
    false
  );
}

export function getBadges(dispatch: Dispatch<AdminActions>) {
  defaultGet(
    dispatch,
    AdminActionTypes.FETCH_BADGES_SUCCESS,
    AdminActionTypes.FETCH_ERROR,
    AdminActionTypes.FETCH_BADGES,
    client().getBadges,
    false,
    false
  );
}

export function patchBadge(
  dispatch: Dispatch<AdminActions>,
  id: BadgeTypeEnum,
  badge: BadgePatch
) {
  defaultGet(
    dispatch,
    AdminActionTypes.PATCH_BADGE_SUCCESS,
    AdminActionTypes.FETCH_ERROR,
    AdminActionTypes.FETCH_BADGES,
    client().patchBadge,
    false,
    false,
    id,
    badge
  );
}

export function createBadge(
  dispatch: Dispatch<AdminActions>,
  badge: BadgeCreate
) {
  defaultGet(
    dispatch,
    AdminActionTypes.PATCH_BADGE_SUCCESS,
    AdminActionTypes.FETCH_ERROR,
    AdminActionTypes.FETCH_BADGES,
    client().createBadge,
    false,
    false,
    badge
  );
}

export function giveBadge(
  dispatch: Dispatch<AdminActions>,
  badge: UserBadgeCreateDelete
) {
  defaultGet(
    dispatch,
    AdminActionTypes.PATCH_BADGE_SUCCESS,
    AdminActionTypes.FETCH_ERROR,
    AdminActionTypes.FETCH_BADGES,
    client().giveBadge,
    false,
    false,
    badge
  );
}

export function removeBadge(
  dispatch: Dispatch<AdminActions>,
  badge: UserBadgeCreateDelete
) {
  defaultGet(
    dispatch,
    AdminActionTypes.PATCH_BADGE_SUCCESS,
    AdminActionTypes.FETCH_ERROR,
    AdminActionTypes.FETCH_BADGES,
    client().removeBadge,
    false,
    false,
    badge
  );
}

export function postGoalEventAdmin(
  dispatch: Dispatch<AdminActions>,
  matchID: IDType,
  teamId: IDType,
  goal1: number,
  goal2: number
) {
  defaultGet(
    dispatch,
    AdminActionTypes.FETCH_EVENT_SUCCESS,
    AdminActionTypes.FETCH_ERROR,
    AdminActionTypes.FETCH_MATCH,
    client().postGoalEvent,
    false,
    { id: matchID },
    matchID,
    90,
    teamId,
    goal1,
    goal2,
    undefined,
    undefined,
    undefined,
    true
  );
}

export function deleteEventAdmin(
  dispatch: Dispatch<AdminActions>,
  eventId: IDType,
  matchID: IDType
) {
  defaultGet(
    dispatch,
    AdminActionTypes.DELETE_EVENT_SUCCESS,
    AdminActionTypes.FETCH_ERROR,
    AdminActionTypes.FETCH_MATCH,
    client().deleteEvent,
    false,
    { id: matchID },
    eventId
  );
}

export function createNews(dispatch: Dispatch<AdminActions>, news: BlogCreate) {
  defaultGet(
    dispatch,
    AdminActionTypes.FETCH_NEWS_SUCCESS,
    AdminActionTypes.FETCH_ERROR,
    AdminActionTypes.FETCH_NEWS,
    client().createNews,
    false,
    false,
    news
  );
}

export function bulkDeleteMatches(
  dispatch: Dispatch<AdminActions>,
  ids: IDType[]
) {
  defaultGet(
    dispatch,
    AdminActionTypes.BULK_DELETE_SUCCESS,
    AdminActionTypes.FETCH_ERROR,
    AdminActionTypes.BULK_DELETE,
    client().bulkDeleteMatches,
    false,
    false,
    ids
  );
}

export function bulkPatchMatches(
  dispatch: Dispatch<AdminActions>,
  patches: MatchLeaguePatch[]
) {
  defaultGet(
    dispatch,
    AdminActionTypes.PATCH_MATCH_SUCCESS,
    AdminActionTypes.FETCH_ERROR,
    AdminActionTypes.FETCH_MATCH,
    client().bulkPatchMatches,
    false,
    false,
    patches
  );
}

export function csvImportResult(
  dispatch: Dispatch<AdminActions>,
  taskId: string
) {
  defaultGet(
    dispatch,
    AdminActionTypes.MATCH_IMPORT_FINISH_SUCCESS,
    AdminActionTypes.FETCH_ERROR,
    AdminActionTypes.MATCH_IMPORT,
    client().csvImportResult,
    false,
    false,
    taskId
  );
}

// relo ad
