import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ConnectedProps } from "react-redux";
import { faAdd, faTrash, faUndo } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  LeagueCategoryEnum,
  LeagueRatingEnum,
  SportEnum,
  TeamListElement,
} from "../../../../../client/api";
import { translate } from "../../../../../config/translator";
import CheckboxInput from "../../CheckboxInput";
import { ClavaContext } from "../../../../../config/contexts";
import { IDType } from "../../../../../config/types";
import { connector } from "./redux";
import TeamName from "../../../../components/Team/TeamName";
import Loading from "../../../../components/Loading";
import SearchInput from "../../SearchInput";
import ClavaSwitch from "../../../../components/ClavaSwitch";
import ClavaTextInput from "../../../../components/ClavaTextInput";
import ClavaDropdown from "../../../../components/ClavaDropdown";
import ClavaButton from "../../../../components/ClavaButton";

type TeamRowProps = {
  team: TeamListElement;
  onClick: (id: IDType) => void;
  deleted: boolean;
  idx: number;
};

const TeamRow: React.FC<TeamRowProps> = ({ team, idx, onClick, deleted }) => {
  const onClickDelete = useCallback(() => {
    onClick(team.id);
  }, [onClick, team]);
  return (
    <div
      className={`py-1 flex flex-row items-center justify-between ${
        idx % 2 === 0 ? "bg-light-gray dark:bg-light-gray-dark" : ""
      } ${deleted ? "text-live text-decoration-line-through" : ""}`}
    >
      <TeamName team={team} live={deleted} />

      <ClavaButton secondary onClick={onClickDelete}>
        <FontAwesomeIcon
          icon={deleted ? faUndo : faTrash}
          className="text-danger"
        />
      </ClavaButton>
    </div>
  );
};

const EditCreateLeague: React.FC<ConnectedProps<typeof connector>> = ({
  teamsResult,
  searchTeam,
  selectedLeague,
  onSubmit,
  aois,
  statusSearch,
  teams,
  statusTeams,
  getTeams,
  addTeamsToLeague,
  removeTeamFromLeague,
}) => {
  const { l } = useContext(ClavaContext);
  const [textDE, setTextDE] = useState(selectedLeague?.name.textDE ?? "");
  const [textIT, setTextIT] = useState(selectedLeague?.name.textIT ?? "");
  const [textEN, setTextEN] = useState(selectedLeague?.name.textEN ?? "");
  const [hasPlayerStatistics, setHasPlayerStatistics] = useState(
    selectedLeague?.hasPlayerStatistics ?? true
  );
  const [hasTeamStatistics, setHasTeamStatistics] = useState(
    selectedLeague?.hasTeamStatistics ?? true
  );
  const [hasLineup, setHasLineup] = useState(selectedLeague?.hasLineup ?? true);
  const [hasManOfTheMatch, setHasManOfTheMatch] = useState(
    selectedLeague?.hasManOfTheMatch ?? true
  );
  const [hasFeed, setHasFeed] = useState(selectedLeague?.hasFeed ?? true);
  const [hasScorers, setHasScorers] = useState(
    selectedLeague?.hasScorers ?? true
  );
  const [hasSquad, setHasSquad] = useState(selectedLeague?.hasSquad ?? true);
  const [hasBetting, setHasBetting] = useState(
    selectedLeague?.hasBetting ?? true
  );
  const [amountPlayers, setAmountPlayers] = useState(
    selectedLeague?.amountPlayers ?? 11
  );
  const [category, setCategory] = useState<LeagueCategoryEnum>(
    selectedLeague?.category ?? LeagueCategoryEnum.LEAGUE
  );
  const [deletedTeams, setDeletedTeams] = useState<IDType[]>([]);
  const [teamQuery, setTeamQuery] = useState("");
  const [foundTeam, onFoundTeam] = useState<TeamListElement>();
  const [addedTeams, setAddedTeams] = useState<TeamListElement[]>([]);
  const addTeam = useCallback(() => {
    if (foundTeam) {
      setAddedTeams((at) => at.concat([foundTeam]));
    }
  }, [foundTeam]);
  const [matchSectionAmount, setMatchSectionAmount] = useState(
    selectedLeague?.matchSectionAmount ?? 2
  );
  const [matchSectionDurationMinutes, setMatchSectionDurationMinutes] =
    useState(selectedLeague?.matchSectionDurationMinutes ?? 45);
  const [
    matchSectionPauseDurationMinutes,
    setMatchSectionPauseDurationMinutes,
  ] = useState(selectedLeague?.matchSectionPauseDurationMinutes ?? 15);
  const [amountMatchDaysHalfSeason, setAmountMatchDaysHalfSeason] = useState(
    selectedLeague?.amountMatchDaysHalfSeason ?? 12
  );
  const searchTimeout = useRef(0);
  const onSearchTeam = useCallback(
    (t: string) => {
      window.clearTimeout(searchTimeout.current);
      setTeamQuery(t);
      searchTimeout.current = window.setTimeout(() => {
        searchTeam(t);
      }, 300);
    },
    [searchTeam]
  );

  const teamsOfLeague = useMemo(() => {
    if (!teams) return undefined;
    return teams.response;
  }, [teams]);
  const selectedTeams = useMemo(() => {
    if (teamsOfLeague) return teamsOfLeague.concat(addedTeams);
    return addedTeams;
  }, [teamsOfLeague, addedTeams]);
  const message = useRef<string>();
  useEffect(() => {
    if (selectedLeague) {
      if (!teamsOfLeague && statusTeams !== "loading") {
        getTeams(selectedLeague.id);
      }
    }
  }, [statusTeams, selectedLeague, getTeams, teamsOfLeague]);
  const [tableRating, setTableRating] = useState<LeagueRatingEnum>(
    selectedLeague?.tableRating ?? LeagueRatingEnum.SOUTHTYROL
  );
  const [matchDays, setMatchDays] = useState(selectedLeague?.matchDays ?? 1);
  const [main, setMain] = useState<boolean>(selectedLeague?.main ?? true);
  const [areaOfInterestIds, setAreaOfInterestIds] = useState<IDType[]>(
    selectedLeague?.areasOfInterest?.map((a) => a.id) ?? []
  );
  const [sports, setSports] = useState(
    selectedLeague?.sports ?? SportEnum.SOCCER
  );

  const [year, setYear] = useState(selectedLeague?.year ?? 2023);
  const pickerItems = useMemo(
    () => [
      {
        key: LeagueCategoryEnum.LEAGUE,
        label: translate(LeagueCategoryEnum.LEAGUE, l),
      },
      {
        key: LeagueCategoryEnum.CUP,
        label: translate(LeagueCategoryEnum.CUP, l),
      },
      {
        key: LeagueCategoryEnum.YOUTH,
        label: translate(LeagueCategoryEnum.YOUTH, l),
      },
      {
        key: LeagueCategoryEnum.VSS_YOUTH,
        label: translate(LeagueCategoryEnum.VSS_YOUTH, l),
      },
      {
        key: LeagueCategoryEnum.VSS_FREE_TIME,
        label: translate(LeagueCategoryEnum.VSS_FREE_TIME, l),
      },
      {
        key: LeagueCategoryEnum.VIENNA,
        label: translate(LeagueCategoryEnum.VIENNA, l),
      },
      {
        key: LeagueCategoryEnum.WOMEN,
        label: translate(LeagueCategoryEnum.WOMEN, l),
      },
    ],
    [l]
  );
  const ratingItems = useMemo(
    () => [
      {
        key: LeagueRatingEnum.SOUTHTYROL,
        label: translate("southtyrol", l),
      },
      {
        key: LeagueRatingEnum.TRENTINO,
        label: translate("trentino", l),
      },
    ],
    [l]
  );
  const sportsItems = useMemo(
    () => [
      {
        key: SportEnum.SOCCER,
        label: translate(SportEnum.SOCCER, l),
      },
      {
        key: SportEnum.HOCKEY,
        label: translate(SportEnum.HOCKEY, l),
      },
    ],
    [l]
  );
  const onSetSports = useCallback((sp: SportEnum | undefined) => {
    if (sp) setSports(sp);
  }, []);
  const onSubmitCont = useCallback(() => {
    if (areaOfInterestIds.length !== 0) {
      if (selectedLeague) {
        const reallyAdded = addedTeams.filter(
          (aT) => deletedTeams.indexOf(aT.id) === -1
        );
        if (reallyAdded.length !== 0) {
          addTeamsToLeague(
            selectedLeague.id,
            reallyAdded.map((aT) => aT.id)
          );
        }
        if (deletedTeams.length !== 0) {
          removeTeamFromLeague(selectedLeague.id, deletedTeams);
        }
      }
      onSubmit({
        name: {
          textEN,
          textIT,
          textDE,
        },
        year,
        category,
        main,
        tableRating,
        areaOfInterestIds,
        matchDays,
        sports,
        matchSectionPauseDurationMinutes,
        matchSectionAmount,
        matchSectionDurationMinutes,
        amountMatchDaysHalfSeason,
      });
      setTimeout(() => {
        setAddedTeams([]);
        setDeletedTeams([]);
        message.current = "success";
        if (selectedLeague) getTeams(selectedLeague.id);
      }, 1000);
    }
  }, [
    addTeamsToLeague,
    addedTeams,
    areaOfInterestIds,
    category,
    deletedTeams,
    getTeams,
    amountMatchDaysHalfSeason,
    main,
    matchDays,
    matchSectionAmount,
    matchSectionDurationMinutes,
    matchSectionPauseDurationMinutes,
    onSubmit,
    removeTeamFromLeague,
    selectedLeague,
    sports,
    tableRating,
    textDE,
    textEN,
    textIT,
    year,
  ]);
  const setTableRatingCont = useCallback(
    (val: undefined | LeagueRatingEnum) => {
      if (val) setTableRating(val);
    },
    []
  );
  const setLeagueCategoryCont = useCallback(
    (val: undefined | LeagueCategoryEnum) => {
      if (val) setCategory(val);
    },
    []
  );
  const onDeleteTeam = useCallback((id: IDType) => {
    setDeletedTeams((del) =>
      del.indexOf(id) === -1 ? del.concat([id]) : del.filter((d) => d !== id)
    );
  }, []);
  const setMatchSectionAmountCont = useCallback((text: string) => {
    setMatchSectionAmount(parseInt(text, 10));
  }, []);
  const setMatchSectionDurationMinutesCont = useCallback((text: string) => {
    setMatchSectionDurationMinutes(parseInt(text, 10));
  }, []);
  const setMatchSectionPauseDurationMinutesCont = useCallback(
    (text: string) => {
      setMatchSectionPauseDurationMinutes(parseInt(text, 10));
    },
    []
  );
  const setYearCont = useCallback((text: string) => {
    setYear(parseInt(text, 10));
  }, []);
  const setMatchDaysCont = useCallback((text: string) => {
    setMatchDays(parseInt(text, 10));
  }, []);
  const setAmountMatchDaysHalfSeasonCont = useCallback((text: string) => {
    setAmountMatchDaysHalfSeason(parseInt(text, 10));
  }, []);
  const setAmountPlayersCont = useCallback((text: string) => {
    setAmountPlayers(parseInt(text, 10));
  }, []);
  return (
    <div className="m-4">
      <div className="flex flex-row items-center justify-evenly py-2">
        <div className="px-2 flex-1">
          <ClavaTextInput
            value={textDE}
            onChange={setTextDE}
            name="nameDE"
            label={translate("nameDE", l)}
          />
        </div>
        <div className="px-2 flex-1">
          <ClavaTextInput
            value={textIT}
            onChange={setTextIT}
            name="nameIT"
            label={translate("nameIT", l)}
          />
        </div>
        <div className="px-2 flex-1">
          <ClavaTextInput
            value={textEN}
            onChange={setTextEN}
            name="nameEN"
            label={translate("nameEN", l)}
          />
        </div>
      </div>
      <div className="flex flex-row items-center justify-evenly py-2">
        <div className="px-2 flex-1">
          <ClavaTextInput
            value={matchSectionAmount.toString()}
            onChange={setMatchSectionAmountCont}
            name="matchSectionAmount"
            label={translate("matchSectionAmount", l)}
          />
        </div>
        <div className="px-2 flex-1">
          <ClavaTextInput
            value={matchSectionDurationMinutes.toString()}
            onChange={setMatchSectionDurationMinutesCont}
            name="matchSectionDurationMinutes"
            label={translate("matchSectionDurationMinutes", l)}
          />
        </div>
        <div className="px-2 flex-1">
          <ClavaTextInput
            value={matchSectionPauseDurationMinutes.toString()}
            onChange={setMatchSectionPauseDurationMinutesCont}
            name="matchSectionPauseDurationMinutes"
            label={translate("matchSectionPauseDurationMinutes", l)}
          />
        </div>
      </div>
      <div className="py-2">
        <ClavaDropdown
          label={translate("leagueCategory", l)}
          items={pickerItems}
          value={category}
          onChange={setLeagueCategoryCont}
        />
      </div>
      <div className="py-2">
        <ClavaDropdown
          label={translate("leagueRating", l)}
          items={ratingItems}
          value={tableRating}
          onChange={setTableRatingCont}
        />
      </div>
      <div className="flex flex-row items-center justify-evenly py-2">
        <div className="px-2 flex-1">
          <ClavaTextInput
            label={translate("matchDays", l)}
            onChange={setMatchDaysCont}
            value={matchDays.toString()}
            name="matchDays"
          />
        </div>
        <div className="px-2 flex-1">
          <ClavaTextInput
            label={translate("amountMatchDaysHalfSeason", l)}
            onChange={setAmountMatchDaysHalfSeasonCont}
            value={amountMatchDaysHalfSeason.toString()}
            name="amountMatchDaysHalfSeason"
          />
        </div>
        <div className="px-2 flex-1">
          {!!selectedLeague && (
            <ClavaTextInput
              onChange={setAmountPlayersCont}
              name="amountPlayers"
              label={translate("amountPlayers", l)}
              value={amountPlayers.toString()}
            />
          )}
        </div>
      </div>
      <div className="py-2">
        <ClavaTextInput
          label={translate("year", l)}
          onChange={setYearCont}
          value={year.toString()}
          name="year"
        />
      </div>
      <div className="flex flex-row items-center justify-evenly py-2">
        <div className="px-2 flex-1">
          <CheckboxInput
            label="mainLeague"
            onChange={setMain}
            value={main}
            name="mainLeague"
          />
        </div>
        <div className="px-2 flex-1">
          <ClavaDropdown
            items={sportsItems}
            value={sports}
            onChange={onSetSports}
          />
        </div>
      </div>
      <div className="flex flex-row items-center justify-evenly py-2">
        {aois.map((aoi) => (
          <div className="px-2 flex-1" key={`aoi-checkbox-${aoi.id}`}>
            <CheckboxInput
              label={aoi}
              onChange={(checked) => {
                setAreaOfInterestIds((aoiIds) =>
                  aoiIds
                    .filter((aoiID) => aoiID !== aoi.id)
                    .concat(checked ? [aoi.id] : [])
                );
              }}
              name={`aoi${aoi.id}`}
              value={areaOfInterestIds.indexOf(aoi.id) !== -1}
            />
          </div>
        ))}
      </div>
      {!!selectedLeague && (
        <>
          <div className="flex flex-row items-center justify-evenly py-2">
            <div className="px-2 flex-1">
              <ClavaSwitch
                offText="N"
                onText="Y"
                label={translate("hasPlayerStatistics", l)}
                onChange={setHasPlayerStatistics}
                value={hasPlayerStatistics}
              />
            </div>
            <div className="px-2 flex-1">
              <ClavaSwitch
                offText="N"
                onText="Y"
                label={translate("hasTeamStatistics", l)}
                onChange={setHasTeamStatistics}
                value={hasTeamStatistics}
              />
            </div>
            <div className="px-2 flex-1">
              <ClavaSwitch
                offText="N"
                onText="Y"
                label={translate("hasManOfTheMatch", l)}
                onChange={setHasManOfTheMatch}
                value={hasManOfTheMatch}
              />
            </div>
          </div>
          <div className="flex flex-row items-center justify-evenly py-2">
            <div className="px-2 flex-1">
              <ClavaSwitch
                offText="N"
                onText="Y"
                label={translate("hasLineup", l)}
                onChange={setHasLineup}
                value={hasLineup}
              />
            </div>
            <div className="px-2 flex-1">
              <ClavaSwitch
                offText="N"
                onText="Y"
                label={translate("hasFeed", l)}
                onChange={setHasFeed}
                value={hasFeed}
              />
            </div>
            <div className="px-2 flex-1">
              <ClavaSwitch
                offText="N"
                onText="Y"
                label={translate("hasScorers", l)}
                onChange={setHasScorers}
                value={hasScorers}
              />
            </div>
          </div>
          <div className="flex flex-row items-center justify-evenly py-2">
            <div className="px-2 flex-1">
              <ClavaSwitch
                offText="N"
                onText="Y"
                label={translate("hasSquad", l)}
                onChange={setHasSquad}
                value={hasSquad}
              />
            </div>
            <div className="px-2 flex-1">
              <ClavaSwitch
                offText="N"
                onText="Y"
                label={translate("hasBetting", l)}
                onChange={setHasBetting}
                value={hasBetting}
              />
            </div>
            <div className="px-2 flex-1" />
          </div>
        </>
      )}
      {!!selectedLeague && (
        <div className="flex flex-col items-stretch py-2">
          <div className="min-w-full">
            <h6 className="font-semibold">{translate("teams", l)}</h6>
          </div>
          {statusTeams === "loading" ? (
            <Loading small />
          ) : (
            selectedTeams.map((team, idx) => (
              <TeamRow
                idx={idx}
                deleted={deletedTeams.indexOf(team.id) !== -1}
                onClick={onDeleteTeam}
                key={`team-${team.id}`}
                team={team}
              />
            ))
          )}
        </div>
      )}
      {!!selectedLeague && (
        <div className="flex flex-row items-center justify-evenly py-2">
          <div className="px-2">
            <SearchInput
              onChange={onSearchTeam}
              searching={statusSearch === "loading"}
              onSelect={onFoundTeam}
              isFocused={!foundTeam && teamQuery.length > 2}
              selectedItem={foundTeam}
              name="searchTeam"
              value={teamQuery}
              items={teamsResult}
            />
          </div>
          <div className="px-2">
            <ClavaButton onClick={addTeam} disabled={!foundTeam}>
              <div className="flex flex-row items-center justify-center">
                {translate("addTeam", l)}
                <FontAwesomeIcon icon={faAdd} className="ml-2 w-4 h-4" />
              </div>
            </ClavaButton>
          </div>
        </div>
      )}
      {message.current && (
        <div className="py-2">
          <span
            className={
              message.current === "success" ? "text-green" : "text-red"
            }
          >
            {translate(
              message.current === "success" ? "saveSuccess" : "saveFailed",
              l
            )}
          </span>
        </div>
      )}
      <ClavaButton onClick={onSubmitCont}>{translate("submit", l)}</ClavaButton>
    </div>
  );
};

export default connector(EditCreateLeague);
// reload
