import { ConnectedProps } from "react-redux";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import { ClavaContext } from "../../../../config/contexts";
import { connector } from "./redux";
import { showTranslated, translate } from "../../../../config/translator";
import SearchInput from "../SearchInput";
import {
  League,
  LeagueCreate,
  LeagueListElement,
  LeaguePatch,
} from "../../../../client/api";
import EditCreateLeague from "./EditCreate";
import ClavaSwitch from "../../../components/ClavaSwitch";
import ClavaButton from "../../../components/ClavaButton";
import ClavaTextInput from "../../../components/ClavaTextInput";

const togglerClass =
  "flex flex-row items-center justify-between p-4 border-b border-b-light-gray dark:border-b-light-gray-dark";

const AdminpanelLeague: React.FC<ConnectedProps<typeof connector>> = ({
  league,
  aois,
  getAois,
  getLeague,
  status,
  searchLeague,
  deleteLeague,
  createLeague,
  patchLeague,
  duplicateLeague,
  searching,
  leagues,
}) => {
  const { l } = useContext(ClavaContext);
  const { adminMethod, adminElemId } = useParams();
  const [method, setMethod] = useState<string>(adminMethod ?? "search");
  const [query, setQuery] = useState("");
  const [wantDuplicate, setWantDuplicate] = useState(false);
  const [year, setYear] = useState(2024);
  const [deactivateOld, setDeactivateOld] = useState(false);
  const [selectedLeague, setSelectedLeague] = useState<League>();
  const isCreating = useRef(false);
  const isDownloading = useRef(false);
  const timeout = useRef<number>(-1);
  useEffect(() => {
    if (
      adminElemId &&
      !Number.isNaN(parseInt(adminElemId, 10)) &&
      status === "idle"
    ) {
      if (!league) getLeague(parseInt(adminElemId, 10));
      if (league) {
        setSelectedLeague(league);
      }
    } else if (adminMethod === "edit" && status !== "loading") {
      setMethod("search");
    } else if (league && isCreating.current && status !== "loading") {
      isCreating.current = false;
      setSelectedLeague(undefined);
      setSelectedLeague(league);
      setMethod("edit");
    } else if (league && isDownloading.current && status !== "loading") {
      isDownloading.current = false;
      setSelectedLeague(league);
    }
  }, [adminElemId, getLeague, method, status, league, adminMethod]);
  const reset = useCallback(() => {
    setSelectedLeague(undefined);
  }, []);
  const toggleCreate = useCallback(() => {
    reset();
    setMethod((m) => (m === "create" ? "search" : "create"));
  }, [reset]);
  const toggleReorder = useCallback(() => {
    setMethod((m) => (m === "reorder" ? "search" : "reorder"));
  }, []);
  const toggleSearch = useCallback(() => {
    setMethod("search");
  }, []);
  const toggleEdit = useCallback(() => {
    if (selectedLeague) {
      setMethod((m) => (m === "edit" ? "search" : "edit"));
    } else {
      reset();
      setMethod("search");
    }
  }, [reset, selectedLeague]);
  const toggleDelete = useCallback(() => {
    setMethod((m) => (m === "delete" ? "search" : "delete"));
  }, []);
  const onSearch = useCallback(
    (q: string) => {
      if (timeout.current !== -1) {
        window.clearTimeout(timeout.current);
      }
      setQuery(q);
      timeout.current = window.setTimeout(() => {
        searchLeague(q);
      }, 500);
    },
    [searchLeague]
  );
  const onEdit = useCallback(
    (leaguePatch: LeaguePatch) => {
      if (selectedLeague) {
        isDownloading.current = true;
        patchLeague(selectedLeague.id, leaguePatch);
      }
    },
    [patchLeague, selectedLeague]
  );
  const onCreate = useCallback(
    (leagueCreate: LeagueCreate) => {
      isCreating.current = true;
      createLeague(leagueCreate);
    },
    [createLeague]
  );
  const onDelete = useCallback(() => {
    if (selectedLeague) {
      isDownloading.current = true;
      deleteLeague(selectedLeague.id);
      setMethod("search");
    }
  }, [deleteLeague, selectedLeague]);
  useEffect(() => {
    if (aois.length === 0) getAois();
  }, [aois, getAois]);
  const setSelectedLeagueCont = useCallback(
    (leag: LeagueListElement | undefined) => {
      if (leag) {
        isDownloading.current = true;
        getLeague(leag.id);
      } else setSelectedLeague(undefined);
    },
    [getLeague]
  );
  const toggleDuplicateLeague = useCallback(() => {
    setWantDuplicate((dp) => !dp);
  }, []);

  const onDuplicateLeague = useCallback(() => {
    if (selectedLeague) {
      duplicateLeague(selectedLeague.id, year, deactivateOld);
    }
  }, [deactivateOld, duplicateLeague, selectedLeague, year]);
  const setYearCont = useCallback((text: string) => {
    setYear(parseInt(text, 10));
  }, []);
  return (
    <div>
      <fieldset
        className={`overflow-hidden mb-2 self-stretch flex flex-col items-stretch justify-start rounded-xl bg-bg-secondary dark:bg-bg-secondary-dark shadow-shadow dark:shadow-shadow-dark drop-shadow-sm transition-all ${
          method === "search" ? "max-h-[10000px]" : "max-h-14"
        }`}
      >
        <button className={togglerClass} onClick={toggleSearch} type="button">
          <h6 className="font-bold">{translate("search", l)}</h6>
          <FontAwesomeIcon
            icon={method === "search" ? faChevronUp : faChevronDown}
            className="w-6 h-6"
          />
        </button>
        <div className="m-4">
          <SearchInput
            value={query}
            onChange={onSearch}
            label={translate("searchLeagues", l)}
            isFocused={method === "search"}
            selectedItem={selectedLeague}
            name="searchAds"
            onSelect={setSelectedLeagueCont}
            items={leagues}
            searching={searching}
          />
          {selectedLeague && !wantDuplicate && (
            <div className="flex flex-row items-center justify-evenly mt-4">
              <ClavaButton onClick={toggleEdit}>
                {translate("editLeague", l)}
              </ClavaButton>
              <ClavaButton onClick={toggleDuplicateLeague}>
                {translate("duplicateLeague", l)}
              </ClavaButton>
              <ClavaButton onClick={toggleDelete}>
                {translate("deleteLeague", l)}
              </ClavaButton>
            </div>
          )}
          {wantDuplicate && (
            <div className="flex flex-row items-center justify-evenly mt-4">
              <div className="flex-1 px-1">
                <ClavaTextInput
                  onChange={setYearCont}
                  label={translate("year", l)}
                  value={year.toString(10)}
                />
              </div>
              <div className="flex-1 px-1">
                <ClavaSwitch
                  offText="N"
                  onText="Y"
                  label={translate("deactivateOld", l)}
                  value={deactivateOld}
                  onChange={setDeactivateOld}
                />
              </div>
              <div className="flex-1 px-1">
                <ClavaButton onClick={onDuplicateLeague}>
                  {translate("duplicateLeague", l)}
                </ClavaButton>
              </div>
              <div className="flex-1 px-1">
                <ClavaButton secondary onClick={toggleDuplicateLeague}>
                  {translate("cancel", l)}
                </ClavaButton>
              </div>
            </div>
          )}
        </div>
      </fieldset>
      <fieldset
        className={`overflow-hidden mb-2 self-stretch flex flex-col items-stretch justify-start rounded-xl bg-bg-secondary dark:bg-bg-secondary-dark shadow-shadow dark:shadow-shadow-dark drop-shadow-sm transition-all ${
          method === "reorder" ? "max-h-[10000px]" : "max-h-14"
        }`}
      >
        <button className={togglerClass} onClick={toggleReorder} type="button">
          <h6 className="font-bold">{translate("reorder", l)}</h6>
          <FontAwesomeIcon
            icon={method === "reorder" ? faChevronUp : faChevronDown}
            className="w-6 h-6"
          />
        </button>
        {method === "reorder" ? <span>Temporarily disabled</span> : null}
      </fieldset>

      <fieldset
        className={`overflow-hidden mb-2 self-stretch flex flex-col items-stretch justify-start rounded-xl bg-bg-secondary dark:bg-bg-secondary-dark shadow-shadow dark:shadow-shadow-dark drop-shadow-sm transition-all ${
          method === "create" ? "max-h-[10000px]" : "max-h-14"
        }`}
      >
        <button className={togglerClass} onClick={toggleCreate} type="button">
          <h6 className="font-bold">{translate("createLeague", l)}</h6>
          <FontAwesomeIcon
            icon={method === "create" ? faChevronUp : faChevronDown}
            className="w-6 h-6"
          />
        </button>
        {method === "create" && (
          <EditCreateLeague
            onSubmit={onCreate as (league: LeagueCreate | LeaguePatch) => void}
            selectedLeague={undefined}
            aois={aois}
          />
        )}
      </fieldset>
      <fieldset
        className={`overflow-hidden mb-2 self-stretch flex flex-col items-stretch justify-start rounded-xl bg-bg-secondary dark:bg-bg-secondary-dark shadow-shadow dark:shadow-shadow-dark drop-shadow-sm transition-all ${
          method === "edit" ? "max-h-[10000px]" : "max-h-14"
        }`}
      >
        <button className={togglerClass} onClick={toggleEdit} type="button">
          <h6 className="font-bold">
            {translate("editLeague", l) +
              (selectedLeague ? ` [${selectedLeague.id}]` : "")}
          </h6>
          <FontAwesomeIcon
            icon={method === "edit" ? faChevronUp : faChevronDown}
            className="w-6 h-6"
          />
        </button>
        {!!selectedLeague && (
          <EditCreateLeague
            onSubmit={onEdit}
            selectedLeague={selectedLeague}
            aois={aois}
          />
        )}
      </fieldset>
      <fieldset
        className={`overflow-hidden mb-2 self-stretch flex flex-col items-stretch justify-start rounded-xl bg-bg-secondary dark:bg-bg-secondary-dark shadow-shadow dark:shadow-shadow-dark drop-shadow-sm transition-all ${
          method === "delete" ? "max-h-[10000px]" : "max-h-14"
        }`}
      >
        <button className={togglerClass} onClick={toggleDelete} type="button">
          <h6 className="font-bold">
            {translate("deleteLeague", l) +
              (selectedLeague ? ` [${selectedLeague.id}]` : "")}
          </h6>
          <FontAwesomeIcon
            icon={method === "delete" ? faChevronUp : faChevronDown}
            className="w-6 h-6"
          />
        </button>
        <div>
          {!!selectedLeague && (
            <span className="text-red">
              {translate("sureWantDelete", l, {
                "[title]": `"${showTranslated(selectedLeague.name, l)}"`,
              })}
            </span>
          )}
        </div>
        <ClavaButton onClick={onDelete}>{translate("yes", l)}</ClavaButton>
      </fieldset>
    </div>
  );
};

export default connector(AdminpanelLeague);
// reload
