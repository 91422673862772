import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { connect } from "react-redux";
import { RootState } from "../../../../store";
import { searchAdmin } from "../../../../store/actions/adminActions";
import { SEARCH_USERS } from "../../../../store/actions/types";
import { IDType } from "../../../../config/types";
import { performAction } from "../../../../store/actions/all";
import { fetchAois } from "../../../../store/actions/aoiActions";

const mapper = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  patchUser: (id: IDType) => {
    // TODO
  },
  deleteUser: () => {
    // TODO
  },
  searchUser: (q: string) => {
    performAction({ f: searchAdmin, p: [dispatch, q, SEARCH_USERS] });
  },
  getAois: () => {
    performAction({ f: fetchAois, p: [dispatch] });
  },
});

const props = (state: RootState) => ({
  user: state.admin.user,
  users: state.admin.users,
  searching: state.admin.statusSearch === "loading",
  aois: state.aois.value,
  status: state.admin.status,
});

export const connector = connect(props, mapper);
// reload
