import React, { useCallback, useContext, useMemo, useState } from "react";
import {
  Ad,
  AdCreate,
  AdPatch,
  AdPositionEnum,
  Image,
} from "../../../../../client/api";
import { translate } from "../../../../../config/translator";
import FileInput from "../../FileInput";
import { formatDate } from "../../../../../config/utils";
import { ClavaContext } from "../../../../../config/contexts";
import ClavaTextInput from "../../../../components/ClavaTextInput";
import ClavaDropdown, {
  DropdownItem,
} from "../../../../components/ClavaDropdown";
import ClavaSwitch from "../../../../components/ClavaSwitch";
import DateInput from "../../../../components/DateInput";
import ClavaButton from "../../../../components/ClavaButton";

type EditCreateProps =
  | {
      selectedAd: undefined;
      onSubmit: (ad: AdCreate) => void;
    }
  | {
      selectedAd: Ad;
      onSubmit: (ad: AdPatch) => void;
    };

const EditCreateAd: React.FC<EditCreateProps> = ({ selectedAd, onSubmit }) => {
  const { l } = useContext(ClavaContext);
  const [name, setName] = useState(selectedAd?.name ?? "");
  const [color, setColor] = useState<string>(selectedAd?.color ?? "");
  const [position, setPosition] = useState<AdPositionEnum>(
    selectedAd?.position ?? AdPositionEnum.HOME_MATCH
  );
  const [priority, setPriority] = useState(selectedAd?.priority ?? 1);
  const [url, setUrl] = useState(selectedAd?.url ?? "");
  const [start, setStart] = useState<Date>(
    new Date(selectedAd?.start ?? new Date().getTime())
  );
  const [stop, setStop] = useState<Date>(
    new Date(selectedAd?.stop ?? new Date().getTime())
  );
  const [paused, setPaused] = useState<boolean>(selectedAd?.paused ?? false);
  const [fileMobile, setFileMobile] = useState<Image | undefined>(
    selectedAd?.imageMobile ?? undefined
  );
  const [fileDesktop, setFileDesktop] = useState<Image | undefined>(
    selectedAd?.imageDesktop ?? undefined
  );
  const [fileUploadError, setFileUploadError] = useState<string>();
  const [useSameFile, setUseSameFile] = useState(true);
  const [trent, setTrent] = useState(false);
  const [sued, setSued] = useState(true);

  const setPositionCont = useCallback((val: AdPositionEnum | undefined) => {
    if (val === AdPositionEnum.TEAM_OF_THE_WEEK) setUseSameFile(true);
    if (val) setPosition(val);
  }, []);

  const onSubmitCont = useCallback(() => {
    if (selectedAd)
      onSubmit({
        url: url === selectedAd.url ? undefined : url,
        start: formatDate(start, l, true),
        stop: formatDate(stop, l, true),
        name,
        priority,
        position,
        imageMobileId: fileMobile?.id,
        imageDesktopId: fileDesktop ? fileDesktop.id : fileMobile?.id,
        color,
        paused,
        areaOfInterestIds:
          sued && trent ? [1, 2] : sued ? [1] : trent ? [2] : [],
      });
    else if (fileMobile) {
      onSubmit({
        url,
        start: formatDate(start, l, true),
        stop: formatDate(stop, l, true),
        name,
        priority,
        position,
        imageMobileId: fileMobile?.id,
        imageDesktopId: fileDesktop ? fileDesktop.id : fileMobile?.id,
        color,
        paused,
        areaOfInterestIds:
          sued && trent ? [1, 2] : sued ? [1] : trent ? [2] : [],
      });
    }
  }, [
    color,
    fileDesktop,
    fileMobile,
    l,
    name,
    onSubmit,
    paused,
    position,
    priority,
    selectedAd,
    start,
    stop,
    sued,
    trent,
    url,
  ]);

  const dropDownItems = useMemo<DropdownItem<AdPositionEnum>[]>(
    () => [
      {
        key: AdPositionEnum.HOME_MATCH,
        label: translate(AdPositionEnum.HOME_MATCH, l),
      },
      {
        key: AdPositionEnum.MATCH_HISTORY_BOTTOM,
        label: translate(AdPositionEnum.MATCH_HISTORY_BOTTOM, l),
      },
      {
        key: AdPositionEnum.LEAGUE_MATCH_MATCH,
        label: translate(AdPositionEnum.LEAGUE_MATCH_MATCH, l),
      },
      {
        key: AdPositionEnum.USER_FEED,
        label: translate(AdPositionEnum.USER_FEED, l),
      },
      {
        key: AdPositionEnum.TEAM_OF_THE_WEEK,
        label: translate(AdPositionEnum.TEAM_OF_THE_WEEK, l),
      },
      {
        key: AdPositionEnum.STANDINGS,
        label: translate(AdPositionEnum.STANDINGS, l),
      },
    ],
    [l]
  );
  const setPriorityCont = useCallback((e: string) => {
    setPriority(parseInt(e, 10));
  }, []);
  const as = useMemo(() => {
    if (position === AdPositionEnum.MATCH_HISTORY_BOTTOM) return 1080 / 120;
    if (position === AdPositionEnum.STANDINGS) return 1080 / 120;
    if (position === AdPositionEnum.TEAM_OF_THE_WEEK) return 788 / 260;
    return 1080 / 300;
  }, [position]);
  return (
    <div className="m-4">
      <div className="py-2">
        <ClavaTextInput
          value={name}
          onChange={setName}
          label={translate("title", l)}
        />
      </div>
      <div className="py-2">
        <ClavaTextInput
          value={url}
          onChange={setUrl}
          label={translate("url", l)}
        />
      </div>
      <div className="py-2">
        <ClavaDropdown
          disabled={!!fileDesktop || !!fileMobile}
          items={dropDownItems}
          value={position}
          withoutChoose
          primary
          onChange={setPositionCont}
          label={translate(
            !!fileDesktop || !!fileMobile
              ? "deleteImageToChoose"
              : "adPosition",
            l
          )}
        />
      </div>
      <div className="py-2">
        <ClavaSwitch
          onChange={setSued}
          label={translate("southtyrol", l)}
          value={sued}
          horizontal
        />
      </div>
      <div className="py-2">
        <ClavaSwitch
          onChange={setTrent}
          label={translate("trentino", l)}
          value={trent}
          horizontal
        />
      </div>
      <div className="py-2">
        <ClavaSwitch
          label={translate("paused", l)}
          onChange={setPaused}
          value={paused}
          horizontal
        />
      </div>
      <div className="py-2">
        <DateInput
          label={translate("startDate", l)}
          onChange={setStart}
          value={start}
          name="startDate"
          type="datetime"
        />
      </div>
      <div className="py-2">
        <DateInput
          label={translate("stopDate", l)}
          onChange={setStop}
          value={stop}
          name="stopDate"
          type="datetime"
        />
      </div>
      <div className="py-2">
        <ClavaTextInput
          value={color}
          onChange={setColor}
          label={translate("color", l)}
        />
      </div>
      <div className="py-2">
        <ClavaTextInput
          type="number"
          value={priority.toString(10)}
          onChange={setPriorityCont}
          label={translate("priority", l)}
        />
      </div>
      <div className="py-2">
        {fileUploadError && <span className="text-red">{fileUploadError}</span>}
        <FileInput
          name="fileMobile"
          aspectRatio={as}
          onChange={setFileMobile}
          setFileUploadError={setFileUploadError}
          preview={fileMobile}
        />
      </div>
      <div className="py-2">
        <ClavaSwitch
          label={translate("useSameFile", l)}
          onChange={setUseSameFile}
          disabled={position === AdPositionEnum.TEAM_OF_THE_WEEK}
          value={useSameFile}
        />
        {!useSameFile && (
          <FileInput
            name="fileDesktop"
            aspectRatio={as}
            onChange={setFileDesktop}
            setFileUploadError={setFileUploadError}
            preview={fileDesktop}
          />
        )}
      </div>
      <div className="py-2">
        <ClavaButton onClick={onSubmitCont}>
          {translate("submit", l)}
        </ClavaButton>
      </div>
    </div>
  );
};

export default EditCreateAd;
